import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { createNewClassroom } from "../../services/classroom.service";

const useStyles = makeStyles((theme) => ({
    addClass: {
        margin: theme.spacing(2),
    },
    form: {
        width: "100%",
        "& > *": {
            minWidth: "30%",
            margin: theme.spacing(1),
        },
    },
}));

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

export default function AddClassroom({ setClassesAdded }) {
    const classes = useStyles();

    const [feedback, setFeedback] = useState(initialFeedback);
    const [newClass, setNewClass] = useState({
        class: "",
        public: 1,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFeedback({ ...initialFeedback, loading: true });
        let toSend = { ...newClass };

        const [data, err] = await createNewClassroom({ ...toSend, public: 0 });

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                hidden: false,
                severity: "success",
            });
            setClassesAdded((n) => n + 1);
        } else {
            setFeedback({
                loading: false,
                message: err,
                hidden: false,
                severity: "error",
            });
        }
    };

    const handleChange = (e) => {
        setNewClass({ ...newClass, [e.target.name]: e.target.value });
        console.log(newClass);
    };

    return (
        <Accordion className={classes.addClass}>
            <AccordionSummary>
                <Typography variant="h5">Create Organization</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
            >
                <Grid
                    container
                    spacing={1}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    {!feedback.hidden && (
                        <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
                            <Alert
                                severity={feedback.severity}
                                style={{ width: "100%" }}
                            >
                                {feedback.message}
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="class"
                            label="Organization"
                            value={newClass.class}
                            required
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Button
                            style={{ height: "100%" }}
                            fullWidth
                            color="primary"
                            variant="outlined"
                            type="submit"
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1.1rem" />
                                ) : (
                                    <Add />
                                )
                            }
                        >
                            Create Organization
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
