import { useTheme } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useContext } from "react";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import Employees from "./components/account-book/employees";
import City from "./components/City/City";
import ClientType from "./components/ClientType/ClientType";
import Sidebar, {
    facultyModules,
    studentModules,
    userModules,
} from "./components/dashboard/Sidebar";
import DescriptionTag from "./components/DescriptionTags/DescriptionTag";
import Loading from "./components/Loading";
import SignIn from "./components/Login/Login";
import CreateBulkPayment from "./components/Payment/BulkPayment/CreateBulkPayment";
import UserContext from "./components/UserContext";

const AsyncHomepage = Loadable({
    loader: () => import("./components/Login/Login"),
    loading: Loading,
});

const AsyncOfflinePage = Loadable({
    loader: () => import("./components/Auth/OfflinePage"),
    loading: Loading,
});

const AsyncUserPrefrences = Loadable({
    loader: () => import("./components/settings/UserPreferences"),
    loading: Loading,
});

const AsyncSimpleDashboard = Loadable({
    loader: () => import("./components/SimpleDashboard/SimpleDashboard"),
    loading: Loading,
});

const AsyncSinglePayment = Loadable({
    loader: () =>
        import("./components/Payment/Payments/SinglePayment/SinglePayment"),
    loading: Loading,
});

const AsyncThankuPage = Loadable({
    loader: () => import("./components/ThankUPage"),
    loading: Loading,
});

const AsyncErrorBoundry = Loadable({
    loader: () => import("./components/ErrorBoundary"),
    loading: Loading,
});

const AsyncAuthorizeAsType = Loadable({
    loader: () => import("./components/Auth/AuthorizeAsTypes"),
    loading: Loading,
});

const AsyncAuthenticate = Loadable({
    loader: () => import("./components/Authenticate"),
    loading: Loading,
});

const AsyncFourOfFour = Loadable({
    loader: () => import("./components/FourOfFour"),
    loading: Loading,
});

const AsyncMultiPermissionAuthorize = Loadable({
    loader: () => import("./components/MultiPermissionAuthorize"),
    loading: Loading,
});

const AsyncCreateRole = Loadable({
    loader: () => import("./components/Rolesystem/CreateRole"),
    loading: Loading,
});

const AsyncLayout = Loadable({
    loader: () => import("./components/layout"),
    loading: Loading,
});

const Fallback = () => {
    return (
        <Alert style={{ margin: "8px" }} severity="error">
            <AlertTitle>Access Denied</AlertTitle>
            You do not have the clearance to access this page
        </Alert>
    );
};

export const Routes = () => {
    const { user } = useContext(UserContext);
    const theme = useTheme();
    return (
        <AsyncLayout
            sidebar={
                user ? (
                    <Sidebar
                        variant={user.baseUser.type}
                        setComponent={() => {}}
                    />
                ) : null
            }
        >
            <Switch>
                <Route exact path="/offline">
                    <AsyncOfflinePage />
                </Route>

                <Route exact path="/login">
                    <SignIn />
                </Route>

                <Route exact path="/clientType">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["READ CLIENT_TYPE"]}
                        >
                            <ClientType />
                            {/* <SelectClassroom
                                variant="student"
                                userId={user?.id}
                                setComponent={() => {}}
                            /> */}
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/description-tags">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["READ DESCRIPTION_TAGS"]}
                        >
                            <DescriptionTag />
                            {/* <SelectClassroom
                                variant="student"
                                userId={user?.id}
                                setComponent={() => {}}
                            /> */}
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/city">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["READ CITY"]}>
                            <City />
                            {/* <SelectClassroom
                                variant="student"
                                userId={user?.id}
                                setComponent={() => {}}
                            /> */}
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/settings">
                    <AsyncAuthenticate>
                        <AsyncUserPrefrences />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/simple-dashboard">
                    {/* <Authenticate> */}
                    <AsyncSimpleDashboard />
                    {/* </Authenticate> */}
                </Route>

                <Route exact path="/role/update/:id">
                    <AsyncAuthenticate>
                        <AsyncCreateRole mode="edit" />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/role/read/:id">
                    <AsyncAuthenticate>
                        <AsyncCreateRole mode="read" />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/:userType/payment/details/:id/:client">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["READ PAYMENT", "READ COLLECTION"]}
                        >
                            <AsyncSinglePayment />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/:userType/payment/collection/details/:id">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["READ PAYMENT", "READ COLLECTION"]}
                        >
                            <AsyncSinglePayment />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="/payment/bulk">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize
                            ops={["CREATE PAYMENT", "READ PAYMENT"]}
                        >
                            <CreateBulkPayment />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route>

                {/* USER */}

                {/*  */}

                {/*  */}

                <Route exact path="/event/submitted">
                    <AsyncThankuPage />
                </Route>

                {/*  */}

                <Route exact path="/employee">
                    <AsyncAuthenticate>
                        <Employees />
                        {/* <SelectClassroom
                            variant="employee"
                            userId={user?.id}
                            setComponent={() => {}}
                        /> */}
                    </AsyncAuthenticate>
                </Route>

                {/* <Route exact path="/employee/:examType/create/">
                    <AsyncAuthenticate>
                        <AsyncMultiPermissionAuthorize ops={["CREATE EXAM"]}>
                            <AsyncCreateProcterExam />
                        </AsyncMultiPermissionAuthorize>
                    </AsyncAuthenticate>
                </Route> */}

                {/* Proctor Exam */}

                <Route exact path="/student/exam-submitted">
                    <AsyncAuthenticate>
                        <AsyncThankuPage />
                    </AsyncAuthenticate>
                </Route>

                {/*  */}

                {Object.values(userModules)
                    .flat()
                    .map((subModule, idx) => (
                        <Route exact path={`${subModule.href}`} key={idx}>
                            <AsyncAuthenticate>
                                <AsyncAuthorizeAsType types={["user"]}>
                                    <AsyncMultiPermissionAuthorize
                                        strategy="some"
                                        ops={subModule.permissions || []}
                                        fallback={<Fallback />}
                                    >
                                        {subModule.component}
                                    </AsyncMultiPermissionAuthorize>
                                </AsyncAuthorizeAsType>
                            </AsyncAuthenticate>
                        </Route>
                    ))}

                {/* FACULTY */}
                {Object.values(facultyModules)
                    .flat()
                    .map((subModule, idx) => (
                        <Route exact path={`${subModule.href}`} key={idx}>
                            <AsyncAuthenticate>
                                <AsyncAuthorizeAsType types={["employee"]}>
                                    <AsyncMultiPermissionAuthorize
                                        strategy="all"
                                        ops={subModule.permissions || []}
                                        fallback={<Fallback />}
                                    >
                                        {subModule.component}
                                    </AsyncMultiPermissionAuthorize>
                                </AsyncAuthorizeAsType>
                            </AsyncAuthenticate>
                        </Route>
                    ))}

                {Object.values(studentModules)
                    .flat()
                    .map((subModule, idx) => (
                        <Route exact path={`${subModule.href}`} key={idx}>
                            <AsyncAuthenticate>
                                <AsyncAuthorizeAsType types={["student"]}>
                                    <AsyncMultiPermissionAuthorize
                                        strategy="all"
                                        ops={subModule.permissions || []}
                                        fallback={<Fallback />}
                                    >
                                        {subModule.component}
                                    </AsyncMultiPermissionAuthorize>
                                </AsyncAuthorizeAsType>
                            </AsyncAuthenticate>
                        </Route>
                    ))}

                <Route exact path="/">
                    <AsyncAuthenticate>
                        <AsyncHomepage
                            logo="icon.png"
                            schoolName="Zama School"
                        />
                    </AsyncAuthenticate>
                </Route>

                <Route exact path="*">
                    <AsyncFourOfFour />
                </Route>
            </Switch>
        </AsyncLayout>
    );
};
