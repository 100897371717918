import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

const endpoint = `${baseAPI}/employee`;

export async function allFaculty(page = 1, limit = 10, filter = {}) {
    const api = `${endpoint}/all-employees`;
    try {
        const response = await axios.get(api, {
            withCredentials: true,
            params: { page, limit, ...filter },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error);

        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export const deleteEmployee = async (id) => {
    const url = `${baseAPI}/employee/soft`;
    try {
        const response = await axios.post(
            url,
            {},
            { withCredentials: true, params: { id } }
        );

        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export async function employeeCount() {
    return (
        await axios.get(`${endpoint}/employee-count`, { withCredentials: true })
    ).data.count;
}

export async function employeeTypeCount() {
    return (await axios.get(`${endpoint}/employee-type-count`)).data.count;
}

export async function allEmployeeTypes() {
    const api = `${endpoint}/all-employee-types`;
    try {
        const response = await axios.get(api, { withCredentials: true });
        return [response.data, null];
    } catch (error) {
        console.log(error);

        return [[], error.response?.data?.message || notConnectedMessage];
    }
}

export async function createNewEmployee(emp) {
    const api = `${endpoint}/create`;
    try {
        const response = await axios.post(api, emp, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error.response);
        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export async function createEmployeeType(payload) {
    const api = `${endpoint}/create-type`;

    try {
        const response = await axios.post(api, payload, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
        });
        return [response.data, null];
    } catch (error) {
        console.log(error.response);

        return [null, error.response?.data?.message || notConnectedMessage];
    }
}

export async function allEmployeeLecturesAndTopics(subject = undefined) {
    try {
        const api = `${endpoint}/all-lectures-and-topics`;

        const response = await axios.get(api, {
            withCredentials: true,
            params: { subject },
        });

        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export const editEmployee = async (id, newEmployee) => {
    try {
        const api = `${endpoint}/`;
        const response = await axios.patch(api, newEmployee, {
            withCredentials: true,
            params: { id },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

// allFaculty()
