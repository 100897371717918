import {
    Button,
    Checkbox,
    FormLabel,
    Grid,
    Icon,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    allFaculty,
    deleteEmployee,
    editEmployee,
} from "../../services/employee.service";
import Authorize from "../Authorize";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import Filteration from "../Filteration/Filteration";
import { RUDTable } from "../Table/RUDTable";
import UserContext from "../UserContext";
import { parseDate } from "../constants";
import AddEmployee from "./add-employee";

const Employees = () => {
    const [employeesAdded, setEmployeesAdded] = useState(0);
    const [filter, setFilter] = useState({
        search: "",
        role: "",
        classroom: "",
        apply: 0,
        checked: false,
        private: "",
        clientType: "",
        city: "",
    });
    const { user } = useContext(UserContext);
    const history = useHistory();

    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            role: "",
            classroom: "",
            apply: 0,
            checked: false,
            private: "",
            clientType: "",
            city: "",
        });
    };
    const handleChange = (event) => {
        setFilter({ ...filter, checked: event.target.checked });
    };

    console.log(filter.private);
    return (
        <>
            <Authorize
                opName="CREATE CLIENT"
                // fallback={
                //     <AuthorizeFallback message="You're not authorized to create a new employee" />
                // }
            >
                <AddEmployee setEmployeesAdded={setEmployeesAdded} />
            </Authorize>
            <Grid container spacing={1} style={{ padding: "1rem" }}>
                {user && !user.classroom && (
                    <>
                        <Autocomplete
                            labelKey="class"
                            label="Classroom"
                            api="/class/all-classes"
                            setOutput={(r) =>
                                setFilter({
                                    ...filter,
                                    classroom: r?.id || null,
                                })
                            }
                            clear={clearFilters}
                            textFieldProps={{
                                variant: "outlined",
                            }}
                        />
                        <FormLabel>
                            No Classroom
                            <Checkbox
                                checked={filter.checked}
                                onChange={handleChange}
                            />
                        </FormLabel>
                    </>
                )}

                <Grid item xs={12} md={3}>
                    <Autocomplete
                        labelKey="name"
                        label="ClientType"
                        api="/clientType/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                clientType: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />{" "}
                </Grid>

                <Grid item xs={12} md={3}>
                    {" "}
                    <Autocomplete
                        labelKey="name"
                        label="City"
                        api="/city/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                city: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({ ...filter, apply: filter.apply + 1 })
                        }
                        style={{ height: "82%" }}
                        size="small"
                    >
                        {" "}
                        Apply Filter
                    </Button>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <span
                style={{
                    borderBottom: `1px solid  ${theme.palette.divider}`,
                    display: "block",
                }}
            ></span>

            <RUDTable
                getter={(page, limit) =>
                    allFaculty(page, limit, {
                        search: filter.search ? filter.search : null,
                        private:
                            filter.private == true
                                ? 1
                                : filter.private === false
                                ? 0
                                : null,
                        // role: filter.role ? filter.role : null,
                        // classroom: filter.classroom ? filter.classroom : null,
                        classroom: filter.classroom
                            ? filter.classroom
                            : null || filter.checked === true
                            ? 0
                            : null,

                        clientType: filter.clientType
                            ? filter.clientType
                            : null,
                        city: filter.city ? filter.city : null,
                    })
                }
                editor={editEmployee}
                deletor={(ids) => deleteEmployee(ids[0])}
                readables={{
                    id: "ID",
                    name: "Name",
                    email: "Email",
                    // _country: "Country",
                    // _city: "City",
                    // _dobParsed: "Date of birth",
                    // _qualification: "Qualification",
                    // _ed: "Education",
                    // _phone: "Phone number",
                    verified: "Verified",
                    // Private: "Private",
                    clientType: "Client Type",
                    city: "City",
                }}
                rowsPreprocessor={(r) => ({
                    ...r,
                    deletionId: r.id,
                    _ed:
                        r?.education?.length === 0
                            ? "Not given"
                            : r?.education || (
                                  <Typography
                                      style={{ color: theme.palette.divider }}
                                  >
                                      N/A
                                  </Typography>
                              ),
                    _dobParsed: parseDate(r?.dob, true) || (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),
                    email: r.baseUser.email,
                    clientType: r.clientType.name,
                    city: r?.city?.name,
                    verified: r.baseUser.verified ? (
                        <Icon style={{ color: theme.palette.success.main }}>
                            <CheckCircle />
                        </Icon>
                    ) : (
                        <Icon style={{ color: theme.palette.error.main }}>
                            <Cancel />
                        </Icon>
                    ),
                    defaultVisibility: r.private
                        ? { id: true, name: "Private" }
                        : { id: false, name: "public" },

                    defaultClientType: r.clientType,
                    defaultCity: r.city,
                    Private: `${r.private == 1 ? "Private" : "Public"}`,
                    classroom: r?.classroom?.class
                        ? r?.classroom?.class
                        : "No classroom",
                    defaultClassroom: r.classroom,
                    _country: r?.country || (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),

                    _qualification: r?.qualification || (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),
                    _phone: r?.phone || (
                        <Typography style={{ color: theme.palette.divider }}>
                            N/A
                        </Typography>
                    ),

                    _verified: r.baseUser.verified
                        ? { id: true, name: "verified" }
                        : { id: false, name: "unverified" },

                    dob: r?.dob?.split("T")[0],
                    qualification:
                        r.qualification == "undergraduate"
                            ? { id: "undergraduate", name: "undergraduate" }
                            : r.qualification == "graduate"
                            ? { id: "graduate", name: "graduate" }
                            : r.qualification == "postgraduate"
                            ? { id: "postgraduate", name: "postgraduate" }
                            : r.qualification == "doctorial"
                            ? { id: "doctorial", name: "doctorial" }
                            : null,
                })}
                ops={{
                    read: "READ CLIENT",
                    edit: "EDIT CLIENT",
                    delete: "DELETE CLIENT",
                }}
                updatingAgents={[employeesAdded, filter.search, filter.apply]}
                editables={[
                    {
                        name: "name",
                        type: "textfield",
                        label: "Name",
                        isRequired: true,
                    },
                    {
                        name: "country",
                        type: "textfield",
                        label: "Country",
                    },

                    {
                        name: "education",
                        type: "textfield",
                        label: "Education",
                    },
                    {
                        name: "phone",
                        type: "textfield",
                        label: "Phone number",
                    },
                    {
                        name: "email",
                        type: "textfield",
                        label: "Email",
                        isRequired: true,
                    },

                    {
                        name: "dob",
                        type: "dateType",
                        label: "Date of birth",
                    },

                    {
                        name: "private",
                        type: "autocomplete",
                        label: "Visibility",
                        isRequired: true,
                        autocomplete: {
                            labelKey: "name",
                            label: "Visibility",
                            defaultOptions: [
                                { id: true, name: "Private" },
                                { id: false, name: "Public" },
                            ],
                            defaultValue: "defaultVisibility",
                        },
                    },

                    {
                        name: "clientType",
                        type: "autocomplete",
                        label: "Client Type",
                        isRequired: true,
                        autocomplete: {
                            labelKey: "name",
                            label: "ClientType",
                            api: "/clientType/get-All",
                            defaultValue: "defaultClientType",
                            // apiParams: { classroom: null },
                        },
                    },

                    {
                        name: "city",
                        type: "autocomplete",
                        label: "City",
                        isRequired: true,
                        autocomplete: {
                            labelKey: "name",
                            label: "City",
                            api: "/city/get-All",
                            defaultValue: "defaultCity",
                            // apiParams: { classroom: null },
                        },
                    },

                    {
                        name: "qualification",
                        type: "autocomplete",
                        label: "Qualification",
                        autocomplete: {
                            labelKey: "name",
                            label: "Qualification",
                            defaultOptions: [
                                { id: "undergraduate", name: "undergraduate" },
                                { id: "graduate", name: "graduate" },
                                { id: "postgraduate", name: "postgraduate" },
                                { id: "doctorial", name: "doctorial" },
                            ],
                            defaultValue: "qualification",
                        },
                    },

                    {
                        name: "verified",
                        type: "autocomplete",
                        label: "Verification",
                        isRequired: true,

                        autocomplete: {
                            labelKey: "name",
                            label: "Verification",
                            defaultOptions: [
                                { id: true, name: "Verified" },
                                { id: false, name: "Unverified" },
                            ],
                            defaultValue: "_verified",
                        },
                    },
                ]}
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                        // fields={[
                        //     <Box
                        //         display="flex"
                        //         flexDirection="column"
                        //         width="100%"
                        //         style={{ gap: theme.spacing(2) }}
                        //     >
                        //         {user && !user.classroom && (
                        //             <>
                        //                 <Autocomplete
                        //                     labelKey="class"
                        //                     label="Classroom"
                        //                     api="/class/all-classes"
                        //                     setOutput={(r) =>
                        //                         setFilter({
                        //                             ...filter,
                        //                             classroom: r?.id || null,
                        //                         })
                        //                     }
                        //                     clear={clearFilters}
                        //                     textFieldProps={{
                        //                         variant: "outlined",
                        //                     }}
                        //                 />
                        //                 <FormLabel>
                        //                     No Classroom
                        //                     <Checkbox
                        //                         checked={filter.checked}
                        //                         onChange={handleChange}
                        //                     />
                        //                 </FormLabel>
                        //             </>
                        //         )}

                        //         <Autocomplete
                        //             labelKey="name"
                        //             label="ClientType"
                        //             api="/clientType/get-All"
                        //             setOutput={(r) =>
                        //                 setFilter({
                        //                     ...filter,
                        //                     clientType: r?.id || null,
                        //                 })
                        //             }
                        //             clear={clearFilters}
                        //             textFieldProps={{
                        //                 variant: "outlined",
                        //             }}
                        //         />

                        //         <Autocomplete
                        //             labelKey="name"
                        //             label="City"
                        //             api="/city/get-All"
                        //             setOutput={(r) =>
                        //                 setFilter({
                        //                     ...filter,
                        //                     city: r?.id || null,
                        //                 })
                        //             }
                        //             clear={clearFilters}
                        //             textFieldProps={{
                        //                 variant: "outlined",
                        //             }}
                        //         />
                        //     </Box>,
                        // ]}
                    />
                }
                customCols={[]}
                storageKey="employee"
            />
        </>
    );
};

export default Employees;
