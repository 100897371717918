import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";
import { ArchiveService } from "./ArchiveService";

export class RolesService {
    static url = `${baseAPI}/role`;

    static async getRoles(
        page = 0,
        limit = 10,
        filter: any = {},
        isUserSuperAdmin = false
    ) {
        try {
            const response = await axios.get(RolesService.url + "/all-roles", {
                withCredentials: true,
                params: {
                    page: filter.search ? undefined : page,
                    limit: filter.search ? undefined : limit,
                    ...filter,
                },
            });

            const withOutAdminRow = {
                ...response.data,

                rows: response.data?.rows.filter(
                    (row: any) => row.name !== "Admin"
                ),
            };

            return [
                isUserSuperAdmin
                    ? response.data
                    : {
                          ...withOutAdminRow,
                          count: withOutAdminRow.rows.length ?? 0,
                      },
                null,
            ];
        } catch (err: any) {
            console.log(err);
            return [
                { count: 0, ops: [] },
                err.response?.data?.message || notConnectedMessage,
            ];
        }
    }

    static async getRolesWithCats(page = 0, limit = 10, filter: any = {}) {
        try {
            const response = await axios.get(RolesService.url + "/category", {
                withCredentials: true,
                params: {
                    page: filter.search ? undefined : page,
                    limit: filter.search ? undefined : limit,
                    ...filter,
                },
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [
                { count: 0, ops: [] },
                err.response?.data?.message || notConnectedMessage,
            ];
        }
    }

    static async createAdminByRole(user: any) {
        try {
            const response = await axios.post(
                RolesService.url + "/create-admin",
                user,
                { withCredentials: true }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteRole(id: string | number) {
        try {
            const response = await axios.delete(RolesService.url + "/", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getRolesCategories(page = 1, limit = 100, filter = {}) {
        try {
            const response = await axios.get(RolesService.url + "/category", {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async createRoleFromOps(
        ops: string[],
        name: string,
        description: string
    ) {
        try {
            const response = await axios.post(
                RolesService.url + "/from-ops",
                { name, ops, description },
                { withCredentials: true }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteRolePermenantly(id: any) {
        try {
            const response = await axios.post(
                ArchiveService.url + "/role/delete",
                {},
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}

// import axios from "axios";
// import { baseAPI, notConnectedMessage } from "../components/constants";

// export class RoleService {
//     static url = `${baseAPI}/rolesystem`;

//     static async createRoleFromOps(
//         ops: string[],
//         name: string,
//         description: string
//     ) {
//         try {
//             const response = await axios.post(
//                 RoleService.url + "/role/from-ops",
//                 { name, ops, description },
//                 { withCredentials: true }
//             );
//             return [response.data, null];
//         } catch (err: any) {
//             console.log(err);
//             return [null, err.response?.data?.message || notConnectedMessage];
//         }
//     }

//     static async getOps(page = 1, limit = 20, filter = {}) {
//         try {
//             const response = await axios.get(RoleService.url + "/op", {
//                 params: { page, limit, ...filter },
//                 withCredentials: true,
//             });

//             return [response.data, null];
//         } catch (err: any) {
//             return [null, err.response?.data?.message || notConnectedMessage];
//         }
//     }
//     static async getRoles(page = 1, limit = 100, filter = {}) {
//         try {
//             const response = await axios.get(RoleService.url + "/role", {
//                 withCredentials: true,
//                 params: { page, limit, ...filter },
//             });
//             return [response.data, null];
//         } catch (err: any) {
//             console.log(err.response);
//             return [null, err.response?.data?.message || notConnectedMessage];
//         }
//     }

//     static async getRolesCategories(page = 1, limit = 100, filter = {}) {
//         try {
//             const response = await axios.get(RoleService.url + "/op-category", {
//                 withCredentials: true,
//                 params: { page, limit, ...filter },
//             });
//             return [response.data, null];
//         } catch (err: any) {
//             console.log(err.response);
//             return [null, err.response?.data?.message || notConnectedMessage];
//         }
//     }

//     static async deleteRoleSoft(id: string | number) {
//         try {
//             const response = await axios.delete(
//                 RoleService.url + "/role/soft",
//                 {
//                     withCredentials: true,
//                     params: { id },
//                 }
//             );
//             return [response.data, null];
//         } catch (err: any) {
//             return [null, err.response?.data?.message || notConnectedMessage];
//         }
//     }

//     static async deleteRoleHard(id: string | number) {
//         try {
//             const response = await axios.delete(
//                 RoleService.url + "/role/hard",
//                 {
//                     withCredentials: true,
//                     params: { id },
//                 }
//             );
//             return [response.data, null];
//         } catch (err: any) {
//             return [null, err.response?.data?.message || notConnectedMessage];
//         }
//     }

//     static async editRoles(id: string, newRole: any) {
//         try {
//             const response = await axios.patch(
//                 RoleService.url + "/role",
//                 newRole,
//                 {
//                     withCredentials: true,
//                     params: { id },
//                 }
//             );
//             return [response.data, null];
//         } catch (err: any) {
//             console.log(err.response);
//             return [null, err.response?.data?.message || notConnectedMessage];
//         }
//     }
// }
