import { Image } from "@material-ui/icons";
import { Grow, IconButton, Modal, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { S3Service } from "../../services/S3Service";
import { awsSettings } from "../constants";

type Props = {
    id: string;
    simpleView?: boolean;
    objectFit?: string;
};

const PaymentImagePreview = ({
    id,
    simpleView,
    objectFit = "contain",
}: Props) => {
    const [open, setOpen] = useState(simpleView ? simpleView : false);
    const [fetchImage, setFectch] = useState(false);
    const [pic, setPic] = useState<any>();
    const [pdf, setPdf] = useState<any>();
    const theme = useTheme();

    // const getImage = async () => {
    //     const profilePicKey = awsSettings.paymentDirTest + "/" + id;
    //     const url = await S3Service.getS3ByKey(profilePicKey);

    //     if (url) {
    //         try {
    //             const response = await fetch(url, { method: "GET" });
    //             const blob = await response.blob();

    //             const blobURL = URL.createObjectURL(blob);
    //             setPic(blobURL);
    //         } catch (error) {}
    //     }
    // };

    const getImageOrPdf = async () => {
        const profilePicKeyBase = `${awsSettings.paymentDir}/${id}`;
        const possibleExtensions = ["jpg", "jpeg", "png", "pdf"]; // Supported extensions

        let found = false; // Track if a valid file is found

        // 1. Try fetching files with extensions
        for (const ext of possibleExtensions) {
            const keyWithExt = `${profilePicKeyBase}.${ext}`;
            const url = await S3Service.getS3ByKey(keyWithExt);

            if (url) {
                try {
                    const response = await fetch(url, { method: "GET" });
                    if (response.ok) {
                        const blob = await response.blob();
                        const blobURL = URL.createObjectURL(blob);

                        // 2. Set state based on the file type
                        if (ext === "pdf") {
                            setPdf(blobURL); // Store PDF URL
                        } else {
                            setPic(blobURL); // Store image URL
                        }
                        found = true;
                        break; // Exit loop once a valid file is found
                    }
                } catch (error) {
                    console.error(
                        `Error fetching file with extension ${ext}:`,
                        error
                    );
                }
            }
        }

        // 3. Fallback: Try fetching without extension (for old files)
        if (!found) {
            const urlWithoutExt = await S3Service.getS3ByKey(profilePicKeyBase);
            if (urlWithoutExt) {
                try {
                    const response = await fetch(urlWithoutExt, {
                        method: "GET",
                    });
                    if (response.ok) {
                        const blob = await response.blob();
                        const blobURL = URL.createObjectURL(blob);

                        // Assume it's an image if fetched without extension
                        setPic(blobURL);
                    }
                } catch (error) {
                    console.error(
                        "Error fetching file without extension:",
                        error
                    );
                }
            }
        }
    };

    // Call getImageOrPdf when the component mounts or id changes
    useEffect(() => {
        if (id) getImageOrPdf();
    }, [fetchImage]);

    return (
        <>
            {!simpleView && (
                <IconButton
                    onClick={() => {
                        setOpen(true);
                        setFectch(true);
                    }}
                    size="small"
                >
                    <Image fontSize="small" htmlColor={"white"} />
                </IconButton>
            )}

            {simpleView ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={pic}
                            alt=""
                            style={{
                                objectFit: "contain",
                                height: "90%",
                                margin: "auto 0",
                            }}

                            // height={420}
                            // width={theme.breakpoints.values.sm}
                        />
                    </div>
                </div>
            ) : (
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Grow in={open}>
                            {/* <Image
              src={pic}
              alt=""
              height={420}
              width={theme.breakpoints.values.sm}
              style={{
                aspectRatio: "16/9",
                objectFit: "contain",
              }}
            /> */}

                            <img
                                src={pic}
                                alt=""
                                style={{
                                    // aspectRatio: "3/5",
                                    objectFit: "contain",
                                    height: "90%",
                                    margin: "auto 0",
                                }}
                            />
                        </Grow>
                    </div>
                </Modal>
            )}

            <div>
                {pdf && (
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <iframe
                            src={pdf}
                            width="90%"
                            height="90%"
                            style={{ border: "0" }}
                        ></iframe>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default PaymentImagePreview;
