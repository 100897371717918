import { Box, makeStyles } from "@material-ui/core";
import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from "react";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import UserContext from "../../UserContext";
import { CreatePayment } from "./CreatePayment/CreatePayment";
import { UserPayment } from "./CreatePayment/UserPayment";
import CreditDebitHistory from "./PaymentTabs/CreditDebitHistory";
import SingleClientCreditDebitHistory from "./PaymentTabs/SingleClientCreditDebitHistory";
import UserCreditDebitHistory from "./PaymentTabs/UserCreditDebitHistory";

type PaymentTab = "credit-debit-history-tab" | "entities-payments-tab";
export type PaymentAssociation = {
    headAmount: number;
    data: string;
    id: number;
    head: any;
};
type RowToUpdate =
    | null
    | (PaymentResponse & {
          _transactionType: "sender" | "receiver";
          paymentAssociations?: PaymentAssociation[];
      });

function Payments() {
    const [refetchCount, setRefetchCount] = useState(0);
    const [rowToUpdate, setRowToUpdate] = useState<RowToUpdate>(null);
    const [activeTab, setActiveTab] = useState<PaymentTab>(
        "credit-debit-history-tab"
    );
    //
    const { user } = useContext(UserContext);

    //
    const classes = useStyles();

    //
    return (
        <PaymentContext.Provider
            value={{
                activeTab,
                rowToUpdate,
                setRowToUpdate,
                refetchCount,
                setRefetchCount,
            }}
        >
            <Box>
                {user.baseUser.type == "user" && user.classroom && (
                    <Box m={2}>
                        <MultiPermissionAuthorize
                            ops={["CREATE PAYMENT", "EDIT PAYMENT"]}
                            strategy="some"
                        >
                            <CreatePayment />
                        </MultiPermissionAuthorize>

                        <CreditDebitHistory />
                    </Box>
                )}

                {user.baseUser.type == "employee" && user.classroom && (
                    <SingleClientCreditDebitHistory />
                )}

                {user.baseUser.type == "user" && !user.classroom && (
                    <Box m={2}>
                        <MultiPermissionAuthorize
                            ops={["CREATE PAYMENT", "EDIT PAYMENT"]}
                            strategy="some"
                        >
                            <UserPayment />
                        </MultiPermissionAuthorize>

                        <UserCreditDebitHistory />
                    </Box>

                    //
                )}

                {/* {user?.baseUser?.type === "user" && user?.classroom && (
                    <Tabs
                        centered
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(e, newTab) =>
                            setActiveTab(newTab as PaymentTab)
                        }
                        value={activeTab}
                        className={classes.tabs}
                    >
                        <Tab
                            label="Credit / Debit History"
                            value="credit-debit-history-tab"
                        />

                        <Tab
                            label="Entities Payments"
                            value="entities-payments-tab"
                            title="Courses, Events and Forms Payments"
                        />
                    </Tabs>
                )} */}

                {/* {activeTab === "credit-debit-history-tab" ? (
                 
                ) : (
                    <EntitiesPayments />
                )} */}
            </Box>
        </PaymentContext.Provider>
    );
}

export default Payments;

const useStyles = makeStyles((theme) => ({
    tabs: {
        margin: theme.spacing(2, 0),
    },
}));

export const PaymentContext = createContext({} as TPaymentContext);

type TPaymentContext = {
    activeTab: PaymentTab;

    rowToUpdate: RowToUpdate;
    setRowToUpdate: Dispatch<SetStateAction<RowToUpdate>>;

    refetchCount: number;
    setRefetchCount: Dispatch<SetStateAction<number>>;
};
