import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class paymentMethodService {
    static url = `${baseAPI}/payment/method`;

    static async createPaymentMethod(data: any) {
        try {
            const response = await axios.post(
                paymentMethodService.url + "/create",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getPaymentMethod(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                paymentMethodService.url + "/get-All",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async updatePaymentMethod(id: number, data: any) {
        try {
            const response = await axios.patch(
                paymentMethodService.url + "/",
                data,
                {
                    params: { id: id },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (error: any) {
            console.log(error);

            return [
                null,
                error.response?.data?.messaggeg || notConnectedMessage,
            ];
        }
    }

    static async deletePaymentMethod(id: number) {
        try {
            const response = await axios.delete(paymentMethodService.url, {
                params: { id: id },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
