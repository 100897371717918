import { Card, CardContent, Typography } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

type simpleDashboarProps = {
    label: string;
    value: string | number | any;
    bg?: CSSProperties["background"];
};
const SimpleDashboardCard = ({ label, value, bg }: simpleDashboarProps) => {
    return (
        <>
            <Card style={{ background: bg }}>
                <CardContent>
                    <Typography
                        color="textSecondary"
                        variant="body1"
                        style={{ marginBottom: "0.5rem" }}
                    >
                        {label}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="h4"
                        style={{ fontWeight: "bold" }}
                    >
                        {value}
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
};

export default SimpleDashboardCard;
