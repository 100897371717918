import { CircularProgress, Hidden } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider, useTheme } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { S3Service } from "../../services/S3Service";
import { getOrgPublicSettings, loginUser } from "../../services/me.service";
import { APIRequestStatus, basePath } from "../constants";

function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="/">
                Account Book
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

type Props = {
    redirectionUrl?: string;
    onLoginSuccess?: (res: any[]) => void;
    loginButtonLabel?: string;
    onlyShowInputs?: boolean;
};

export default function SignIn({
    redirectionUrl,
    onLoginSuccess,
    loginButtonLabel,
    onlyShowInputs,
}: Props) {
    const theme = useTheme();
    const [status, setStatus] = useState({
        message: "",
        severity: "success",
        hidden: true,
    });
    const [settings, setSettings] = useState<any>({});
    const [orgLogoURL, setOrgURL] = useState(`${basePath}/icon.png`);
    const [loginStatus, setLoginStatus] = useState(APIRequestStatus.idle);
    const location = useLocation();

    const { org } = useParams<any>();

    const history = useHistory();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoginStatus(APIRequestStatus.loading);

        const data = new FormData(event.currentTarget);
        const user = {
            email: data.get("email") as string,
            password: data.get("password") as string,
        };

        const [userLoginData, userLoginError] = await loginUser(user);

        if (userLoginError) {
            if (userLoginError.message == "User already logged in") {
                history.push(`/dashboard`);
            }
            setLoginStatus(APIRequestStatus.error);
            setStatus({
                message: userLoginError.message,
                severity: "error",
                hidden: false,
            });
        } else {
            setLoginStatus(APIRequestStatus.success);
            setStatus({
                message: "Login successfull",
                severity: "success",
                hidden: false,
            });

            if (userLoginData.user.baseUser.type == "employee") {
                history.push("/payment");
            } else if (userLoginData.user.baseUser.type == "user") {
                history.push("/dashboard");
            }
        }

        if (onLoginSuccess) onLoginSuccess([userLoginData, userLoginError]);
    };

    const getOrgSettings = async () => {
        const [data, err] = await getOrgPublicSettings(org);

        if (data) {
            const setts: any = {};

            data.settings.forEach((s: any) => {
                setts[s["key"]] = s["value"];
            });
            setSettings(setts);
        }
    };

    const getOrgLogo = async () => {
        if (settings["organization-logo"]) {
            const url = await S3Service.getS3ByKey(
                settings["organization-logo"]
            );
            setOrgURL(url);
        }
    };

    useEffect(() => {
        if (org) {
            getOrgSettings();
        }
    }, []);

    useEffect(() => {
        getOrgLogo();
    }, [settings]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
                <CssBaseline />

                <Grid
                    container
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        minHeight: "100vh",
                    }}
                    // item
                    // lg={12}
                >
                    {!onlyShowInputs && (
                        <Hidden smDown>
                            <Grid item lg={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h1>{`Welcome to ${
                                        settings["organization-name"] ||
                                        "Accounts Book "
                                    }`}</h1>
                                </div>
                            </Grid>
                        </Hidden>
                    )}

                    <Grid item xs={12} md={6} lg={6}>
                        <Box
                            sx={{
                                marginTop: 70,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            {settings["organization-logo"] ? (
                                <img
                                    src={orgLogoURL}
                                    alt="logo"
                                    style={{
                                        maxWidth: "48x",
                                        maxHeight: "48px",
                                    }}
                                />
                            ) : (
                                !onlyShowInputs && (
                                    <Avatar
                                        style={{
                                            margin: theme.spacing(1),
                                            backgroundColor:
                                                theme.palette.primary.main,
                                        }}
                                    >
                                        <LockOutlinedIcon />
                                    </Avatar>
                                )
                            )}

                            {!onlyShowInputs && (
                                <Typography component="h1" variant="h5">
                                    {settings["organization-name"]
                                        ? `${settings["organization-name"]} Sign in`
                                        : "Sign in"}
                                </Typography>
                            )}

                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    variant="outlined"
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    variant="outlined"
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        loginStatus === APIRequestStatus.loading
                                    }
                                    style={{
                                        marginTop: theme.spacing(1),
                                        marginBottom: theme.spacing(0.5),
                                    }}
                                    endIcon={
                                        loginStatus ===
                                        APIRequestStatus.loading ? (
                                            <CircularProgress
                                                size="1rem"
                                                color="primary"
                                            />
                                        ) : (
                                            <svg
                                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                                                focusable="false"
                                                aria-hidden="true"
                                                viewBox="0 0 24 24"
                                                data-testid="LoginIcon"
                                                tabIndex={-1}
                                                // title="Login"
                                            >
                                                <path d="M11 7 9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z"></path>
                                            </svg>
                                        )
                                    }
                                >
                                    {loginButtonLabel ?? "Login"}
                                </Button>
                                {/* <Button
                            fullWidth
                            variant="contained"
                            style={{
                                marginTop: theme.spacing(3),
                                marginBottom: theme.spacing(2),
                            }}
                            href="/api/user/auth/google/login"
                        >
                            Signin with Google
                        </Button> */}
                                <section hidden={status.hidden}>
                                    <Alert severity={status.severity as any}>
                                        {status.message}
                                    </Alert>
                                </section>

                                {/* {!onlyShowInputs && (
                                    <Grid
                                        item
                                        style={{
                                            marginTop: theme.spacing(2),
                                            textAlign: "center",
                                        }}
                                    >
                                        <Link
                                            href="/signup"
                                            variant="body2"
                                            color="textPrimary"
                                            style={{ width: "100%" }}
                                        >
                                            Don't have an account? Sign Up
                                        </Link>
                                    </Grid>
                                )} */}
                            </Box>
                        </Box>

                        <Copyright style={{ margin: theme.spacing(5) }} />
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
