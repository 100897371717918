import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class SimpleDashboardService {
    static url = `${baseAPI}/dashboard`;

    static async getTotalAmount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/total/Amouts", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSingleDayAmount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/single-day/amount", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTotalAdjustment(filter = {}) {
        try {
            const response = await axios.get(this.url + "/adjustment", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTotalRefund(filter = {}) {
        try {
            const response = await axios.get(this.url + "/refund", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSingleDayAdjustedAmount(filter = {}) {
        try {
            const response = await axios.get(
                this.url + "/single-day/adjustment",
                {
                    params: filter,
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTotalAdvance(filter = {}) {
        try {
            const response = await axios.get(this.url + "/advance", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSingleDayAdvanceAmount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/single-day/advance", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSingleDayRefund(filter = {}) {
        try {
            const response = await axios.get(this.url + "/single-day/refund", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getChapterCount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/chapters-count", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTopicsCount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/topics-count", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudentsCount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/students-count", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getPrivateEnrollmentsInClassCount(filter = {}) {
        try {
            const response = await axios.get(
                this.url + "/private-classroom-enrolloments",
                {
                    params: filter,
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getPrivateEnrollmentsInSubjectCount(filter = {}) {
        try {
            const response = await axios.get(
                this.url + "/private-subject-enrolloments",
                {
                    params: filter,
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTotalSentAmount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/send-payments", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTotalReceivedAmount(filter = {}) {
        try {
            const response = await axios.get(this.url + "/receive-payments", {
                params: filter,
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTotalCollectedAmount(filter = {}) {
        try {
            const response = await axios.get(
                this.url + "/receive-payment-collection",
                {
                    params: filter,
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTotalSentPaymentCollection(filter = {}) {
        try {
            const response = await axios.get(
                this.url + "/send-payment-collection",
                {
                    params: filter,
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
