import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    MenuItem,
    TextField,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useContext, useState } from "react";
import { createNewEmployee } from "../../services/employee.service";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import UserContext from "../UserContext";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    form: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            minWidth: "30%",
            margin: theme.spacing(1),
        },
    },
}));

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

const AddEmployee = ({ setEmployeesAdded }) => {
    const classes = useStyles();
    const { user } = useContext(UserContext);

    const [feedback, setFeedback] = useState(initialFeedback);
    const [newEmployee, setNewEmployee] = useState({
        name: "",
        email: "",
        classroom: "",
        password: "",
        country: "",
        city: "",
        education: "",
        qualification: "",
        phone: "",
        dob: "",
        role: "",
        clientType: "",
    });

    const handleChange = (e) => {
        const temp = { ...newEmployee };
        temp[e.target.name] = e.target.value;
        setNewEmployee(temp);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFeedback({ ...initialFeedback, loading: true });

        console.log(newEmployee, "city");

        const [data, err] = await createNewEmployee({
            ...newEmployee,
            verified: true,
            classroom:
                user && user.classroom
                    ? user.classroom.id
                    : newEmployee.classroom,

            password: "1232",
            role: 2,
            city: newEmployee.city,
        });

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                hidden: false,
                severity: "success",
            });
            setEmployeesAdded((n) => n + 1);
        } else {
            setFeedback({
                loading: false,
                message: err,
                hidden: false,
                severity: "error",
            });
        }
    };

    return (
        <Accordion className={classes.root}>
            <AccordionSummary>
                <Typography variant="h5">Create Client</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
            >
                {feedback.loading && <LinearProgress />}
                <section
                    hidden={feedback.hidden}
                    style={{ paddingBottom: "1rem" }}
                >
                    <Alert severity={feedback.severity}>
                        {feedback.message}
                    </Alert>
                </section>

                <Grid
                    container
                    spacing={2}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="name"
                            value={newEmployee.name}
                            onChange={handleChange}
                            label="Client name"
                            required
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="email"
                            value={newEmployee.email}
                            onChange={handleChange}
                            label="Client email"
                            required
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <Autocomplete
                            api="/clientType/get-All"
                            setOutput={(cl) =>
                                setNewEmployee({
                                    ...newEmployee,
                                    clientType: cl?.id || "",
                                })
                            }
                            label="ClientType"
                            labelKey="name"
                            textFieldProps={{ variant: "outlined" }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <Autocomplete
                            api="/city/get-All"
                            setOutput={(ci) =>
                                setNewEmployee({
                                    ...newEmployee,
                                    city: ci?.id || "",
                                })
                            }
                            label="City"
                            labelKey="name"
                            textFieldProps={{ variant: "outlined" }}
                            fullWidth
                        />
                    </Grid>

                    {user && !user.classroom && (
                        <Grid item xs={12} md={4} lg={3}>
                            <Autocomplete
                                api="/class/all-classes-admin"
                                setOutput={(cls) =>
                                    setNewEmployee({
                                        ...newEmployee,
                                        classroom: cls?.id || "",
                                    })
                                }
                                label="Classroom"
                                labelKey="class"
                                textFieldProps={{ variant: "outlined" }}
                                fullWidth
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4} lg={3}>
                        <Autocomplete
                            api="/other/country/"
                            setOutput={(cnt) =>
                                setNewEmployee({
                                    ...newEmployee,
                                    country: cnt?.name || "",
                                })
                            }
                            label="Country"
                            labelKey="name"
                            textFieldProps={{ variant: "outlined" }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="phone"
                            label="Phone number"
                            onChange={handleChange}
                            value={newEmployee.phone}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="dob"
                            label="Date Of Birth"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            value={newEmployee.dob}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="education"
                            label="Education"
                            onChange={handleChange}
                            value={newEmployee.education}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="qualification"
                            select
                            label="Qualification"
                            onChange={handleChange}
                            value={newEmployee.qualification}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value="undergraduate">
                                Undergraduate
                            </MenuItem>
                            <MenuItem value="graduate">Graduate</MenuItem>
                            <MenuItem value="postgraduate">
                                Postgraduate
                            </MenuItem>
                            <MenuItem value="doctoral">Doctoral</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <Button
                            style={{ height: "100%" }}
                            fullWidth
                            color="primary"
                            variant="outlined"
                            type="submit"
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1.1rem" />
                                ) : (
                                    <Add />
                                )
                            }
                        >
                            Create Client
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default AddEmployee;
