import { Chip, useTheme } from "@material-ui/core";

const DescriptionTagsView = ({ row }: any) => {
    const theme = useTheme();

    return (
        <>
            {row.descTagsComponent.map((item: any) => {
                return (
                    <Chip
                        label={item.descTags.name}
                        style={{
                            color: theme.palette.primary.contrastText,
                            backgroundColor: theme.palette.primary.main,
                        }}
                        size="small"
                    />
                );
            })}
        </>
    );
};

export default DescriptionTagsView;
