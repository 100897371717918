import axios from "axios";
import { baseAPI } from "../components/constants";

export class DataImportService {
    static readonly studentCreationEndpoint = `${baseAPI}/baseuser`;
    static readonly subjectsEnrollmentCreationEndpoint = `${baseAPI}/class`;
    static studentCreationColumns = [
        "email",
        "password",
        "name",
        "phone",
        "dob",
        "education",
        "qualification",
        "country",
        "city",
        "clientType",
    ];
    static subjectsEnrollmentCreationColumns = ["email", "subjects"];

    static async createBulkStudents(students: any[], variant: any) {
        const cleanedStudents = [];

        for (const student of students) {
            const clean: any = {};

            for (const col of this.studentCreationColumns) {
                clean[col] = student[col];
            }

            cleanedStudents.push(clean);
        }

        try {
            console.log("Clean data", cleanedStudents);
            const response = await axios.post(
                this.studentCreationEndpoint + "/bulk-create",
                { data: cleanedStudents },
                {
                    withCredentials: true,
                    params: { variant: variant },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log("Error creating bulk students", err);
            return [null, err.response?.data];
        }
    }

    static async createBulkSubjectEnrollments(studentsAndSubjects: any[]) {
        const cleanedData = [];

        for (const dataPoint of studentsAndSubjects) {
            const clean: any = {};

            for (const col of this.subjectsEnrollmentCreationColumns) {
                clean[col] = dataPoint[col];
            }

            cleanedData.push(clean);
        }

        try {
            console.log("Clean data", cleanedData);
            const response = await axios.post(
                this.subjectsEnrollmentCreationEndpoint + "/bulk-create",
                { data: cleanedData },
                { withCredentials: true }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log("Error creating bulk subject enrollments", err);
            return [null, err.response?.data];
        }
    }
}
