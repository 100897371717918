import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from "@material-ui/core";
import { Check, Close, Edit } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import { PaymentHeadService } from "../../../services/PaymentService";
import Authorize from "../../Authorize";
import Filteration from "../../Filteration/Filteration";
import MultiPermissionAuthorize from "../../MultiPermissionAuthorize";
import { RUDTable } from "../../Table/RUDTable";
import UserContext from "../../UserContext";
import { CreateHead } from "./CreateHead";

type Filter = {
    search: string;
    apply: number;
    editable: "all" | "1" | "0";
    visibility: any;
};

const initialFilter: Filter = {
    search: "",
    apply: 0,
    editable: "all",
    visibility: "superadmin",
};

export const Heads = () => {
    const theme = useTheme();
    const { user, permissions } = useContext(UserContext);
    const [changes, setChanges] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [clearFilters, setClearFilters] = useState(false);
    const [editHead, setEditHead] = useState<any>(null);

    const handleEditHead = (row: any) => {
        setEditHead(row);
    };

    console.log(permissions);
    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            apply: 0,
            editable: "all",
            visibility: "",
        });
    };

    useEffect(() => {
        if (user) {
            setFilter((f) => ({
                ...f,
                visibility: !user.classroom ? 1 : 2,
                apply: f.apply + 1,
            }));
        }
    }, [user?.id]);

    return (
        <Box sx={{ p: 1 }}>
            <Authorize opName="CREATE PAYMENT_HEAD">
                <CreateHead setChanges={setChanges} />
            </Authorize>

            <Grid container style={{ padding: "1rem" }}>
                <Grid item xs={4}>
                    <FormLabel>
                        Editable
                        <RadioGroup
                            name="editable"
                            value={filter.editable}
                            row
                            onChange={(e, v) =>
                                setFilter({
                                    ...filter,
                                    [e.target.name]: v as "1" | "0" | "all",
                                })
                            }
                        >
                            <FormControlLabel
                                label="Yes"
                                value="1"
                                control={<Radio />}
                            />

                            <FormControlLabel
                                label="No"
                                value="0"
                                control={<Radio />}
                            />

                            <FormControlLabel
                                label="All"
                                value="all"
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </FormLabel>
                </Grid>
            </Grid>
            <span
                style={{
                    borderBottom: `1px solid  ${theme.palette.divider}`,
                    display: "block",
                }}
            ></span>

            <RUDTable
                // getter={PaymentHeadService.getHeads}

                getter={(page, limit) =>
                    PaymentHeadService.getHeads(page, limit, {
                        search: filter.search ? filter.search : null,
                        editable:
                            filter.editable === "0"
                                ? 0
                                : filter.editable === "1"
                                ? 1
                                : null,
                    })
                }
                readables={{
                    name: "Name",
                    amount: "Amount",
                    Edit: "Edit",
                    Editable: "Editable",
                }}
                rowsPreprocessor={(r) => ({
                    ...r,
                    deletionId: r.id,
                    amount: r.amount == 0 ? "0" : r.amount,
                })}
                deletor={(ids) =>
                    PaymentHeadService.deletePaymentHead(ids[0] as any)
                }
                // editables={[]}
                ops={{
                    read: "READ PAYMENT_HEAD",
                    edit: "EDIT PAYMENT_HEAD",
                    delete: "DELETE PAYMENT_HEAD",
                }}
                updatingAgents={[
                    changes,
                    filter.search,
                    filter.editable,
                    permissions?.length,
                ]}
                storageKey="heads"
                customCols={[
                    {
                        header: "Editable",
                        content: (row) =>
                            row.isAmountEditable ? <Check /> : <Close />,
                    },

                    {
                        header: "Edit",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["EDIT PAYMENT_HEAD"]}
                            >
                                <IconButton onClick={() => handleEditHead(row)}>
                                    <Edit />
                                </IconButton>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
            />
            <Dialog open={editHead !== null} onClose={() => setEditHead(null)}>
                <DialogTitle>
                    <Paper
                        elevation={0}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 2,
                        }}
                    >
                        <Typography variant="h6">Edit</Typography>
                        {/* <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton> */}
                    </Paper>
                </DialogTitle>
                <DialogContent>
                    <CreateHead updateMode row={editHead} />
                </DialogContent>
            </Dialog>
        </Box>
    );
};
export default Heads;
