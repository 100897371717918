import { useTheme } from "@material-ui/core";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { CityService } from "../../services/CityService";
import Authorize from "../Authorize";
import Filteration from "../Filteration/Filteration";
import { RUDTable } from "../Table/RUDTable";
import UserContext from "../UserContext";
import AddCity from "./AddCity";

const City = () => {
    const [cityAdded, setCityAdded] = useState(0);
    const [filter, setFilter] = useState({
        search: "",
    });
    const { user } = useContext(UserContext);
    const history = useHistory();

    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
        });
    };

    return (
        <>
            <Authorize opName="CREATE CITY">
                <AddCity setCityAdded={setCityAdded} />
            </Authorize>

            <RUDTable
                getter={(page, limit) =>
                    CityService.getCity(page, limit, {
                        search: filter.search ? filter.search : null,

                        // role: filter.role ? filter.role : null,
                        // classroom: filter.classroom ? filter.classroom : null,
                    })
                }
                editor={CityService.updateCity}
                deletor={(ids) => CityService.deleteCity(ids[0] as any)}
                readables={{
                    id: "ID",
                    name: "Name",
                }}
                rowsPreprocessor={(r) => ({
                    ...r,
                    deletionId: r.id,
                })}
                ops={{
                    read: "READ CITY",
                    edit: "EDIT CITY",
                    delete: "DELETE CITY",
                }}
                updatingAgents={[cityAdded, filter.search]}
                editables={[
                    {
                        name: "name",
                        type: "textfield",
                        label: "Name",
                        isRequired: true,
                    },
                ]}
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
                customCols={[]}
                storageKey="city"
            />
        </>
    );
};

export default City;
