import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export const GET = async (url: string, params: any = {}, intent?: string) => {
    try {
        const response = await axios.get(baseAPI + url, {
            withCredentials: true,
            params,
            headers: { "x-intent": intent },
        });
        return [response.data, null];
    } catch (err: any) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const POST = async (
    url: string,
    data: any = null,
    params: any = {},
    intent?: string
) => {
    try {
        const response = await axios.post(baseAPI + url, data, {
            withCredentials: true,
            params,
            headers: { "x-intent": intent },
        });

        return [response.data, null];
    } catch (err: any) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const PATCH = async (
    url: string,
    data: any = null,
    params: any = {}
) => {
    try {
        const response = await axios.patch(baseAPI + url, data, {
            withCredentials: true,
            params,
        });
        return [response.data, null];
    } catch (err: any) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const DELETE = async (
    url: string,
    id: number | number[],
    params?: object,
    headers?: object
) => {
    try {
        const response = await axios.delete(baseAPI + url, {
            withCredentials: true,
            params: { id, ...params },
            headers,
        });
        return [response.data, null];
    } catch (err: any) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};
