// import {
//     Box,
//     Table,
//     TableBody,
//     TableCell,
//     TableHead,
//     TableRow,
//     TextField,
//     useTheme,
// } from "@material-ui/core";
// import { ChangeEvent, Dispatch, SetStateAction } from "react";
// import { currencyFormatter } from "../../constants";
// import { PaymentHeadResponse } from "./CreatePayment/CreatePayment";

// type Props = {
//     heads: PaymentHeadResponse[];
//     setHeads: Dispatch<SetStateAction<PaymentHeadResponse[]>>;
// };

// const SelectedHeadsNAmounts = ({ heads, setHeads }: Props) => {
//     console.log("it hits");
//     const theme = useTheme();

//     //
//     function handleChange(
//         event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
//         currHead: PaymentHeadResponse
//     ) {
//         setHeads((currHeads) => {
//             return [
//                 ...currHeads.map((head) =>
//                     head.id === currHead.id
//                         ? { ...head, amount: +event.target.value }
//                         : head
//                 ),
//             ];
//         });
//     }

//     return (
//         <Box
//             border={`1px solid ${theme.palette.grey[600]}`}
//             borderRadius={theme.shape.borderRadius}
//         >
//             <Table size="small">
//                 <TableHead>
//                     <TableCell>SR. No</TableCell>
//                     <TableCell>Heads</TableCell>
//                     <TableCell>Amounts</TableCell>
//                 </TableHead>
//                 <TableBody>
//                     {heads.map((head, index) => (
//                         <TableRow key={head.id}>
//                             <TableCell>{index + 1}</TableCell>

//                             <TableCell>{head.name}</TableCell>

//                             <TableCell>
//                                 {head.isAmountEditable ? (
//                                     <TextField
//                                         // variant="outlined"
//                                         size="small"
//                                         value={head.amount}
//                                         type="number"
//                                         onChange={(event) =>
//                                             handleChange(event, head)
//                                         }
//                                         inputProps={{ min: 0 }}
//                                     />
//                                 ) : (
//                                     currencyFormatter(head.amount)
//                                 )}
//                             </TableCell>
//                         </TableRow>
//                     ))}
//                 </TableBody>
//             </Table>
//         </Box>
//     );
// };

// export default SelectedHeadsNAmounts;

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    useTheme,
} from "@material-ui/core";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { currencyFormatter } from "../../constants";
import { PaymentHeadResponse } from "./CreatePayment/CreatePayment";

type Props = {
    heads: PaymentHeadResponse[];
    setHeads: Dispatch<SetStateAction<PaymentHeadResponse[]>>;
    collections?: any;
    collecteeId?: any;
    setCollections?: any;
};

const SelectedHeadsNAmounts = ({
    heads,
    setHeads,
    collections,
    collecteeId,
    setCollections,
}: Props) => {
    const theme = useTheme();

    function handleChange(
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        currHead: PaymentHeadResponse
    ) {
        setHeads((currHeads) => {
            const updatedHeads = currHeads.map((head) =>
                head.id === currHead.id
                    ? { ...head, amount: +event.target.value }
                    : head
            );

            if (collecteeId) {
                setCollections((prevCollections: any) => ({
                    ...prevCollections,
                    [collecteeId]: {
                        ...prevCollections[collecteeId],
                        heads: updatedHeads,
                    },
                }));
            }

            return updatedHeads;
        });
    }

    return (
        <Box
            border={`1px solid ${theme.palette.grey[600]}`}
            borderRadius={theme.shape.borderRadius}
        >
            {!collecteeId ? (
                <Table size="small">
                    <TableHead>
                        <TableCell>SR. No</TableCell>
                        <TableCell>Heads</TableCell>
                        <TableCell>Amounts</TableCell>
                    </TableHead>
                    <TableBody>
                        {heads.map((head, index) => (
                            <TableRow key={head.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{head.name}</TableCell>
                                <TableCell>
                                    {head.isAmountEditable ? (
                                        <TextField
                                            size="small"
                                            value={head.amount}
                                            type="number"
                                            onChange={(event) =>
                                                handleChange(event, head)
                                            }
                                            inputProps={{
                                                min: 0,
                                                onWheel: (event: any) =>
                                                    event.target.blur(),
                                            }}
                                            onWheel={(ev) =>
                                                ev.preventDefault()
                                            }
                                        />
                                    ) : (
                                        currencyFormatter(head.amount)
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                heads.map((head: any, index: any) =>
                    head.isAmountEditable ? (
                        <TextField
                            size="small"
                            value={head.amount}
                            type="number"
                            onChange={(event) => handleChange(event, head)}
                            inputProps={{
                                min: 0,
                                onWheel: (event: any) => event.target.blur(),
                            }}
                            variant="outlined"
                            onWheel={(ev) => ev.preventDefault()}
                        />
                    ) : (
                        currencyFormatter(head.amount)
                    )
                )
            )}
        </Box>
    );
};

export default SelectedHeadsNAmounts;
