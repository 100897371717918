import { useContext } from "react";
import UserContext from "./UserContext";

interface Props {
    children: any;
    ops: string[];
    fallback?: JSX.Element | null;
    strategy?: "some" | "all";
}

export function MultiPermissionAuthorize({
    children,
    ops,
    fallback = null,
    strategy = "all",
}: Props) {
    // const { permissions } = useContext(UserContext);
    const isAuthorizeUser = useMultipermissionAuthorize(ops, strategy);

    if (isAuthorizeUser) {
        return children;
    } else {
        return fallback;
    }
}

export default MultiPermissionAuthorize;

export function useMultipermissionAuthorize(
    ops: Props["ops"],
    strategy: Props["strategy"] = "all"
) {
    const { permissions } = useContext(UserContext);

    if (
        permissions
            ? strategy === "all"
                ? ops.every((op) => permissions.includes(op))
                : ops.some((op) => permissions.includes(op))
            : true
    ) {
        return true;
    } else {
        return false;
    }
}
