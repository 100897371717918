import {
    Button,
    Grid,
    Icon,
    IconButton,
    Tooltip,
    useTheme,
} from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { useContext, useMemo, useState } from "react";
import {
    allOrganizationAdminUsers,
    allUsers,
    deleteUser,
    editUser,
} from "../../services/user.service";
import Authorize from "../Authorize";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import { CreateUser } from "../dashboard/CreateUser";
import Filteration from "../Filteration/Filteration";
import { RUDTable } from "../Table/RUDTable";
import UserContext from "../UserContext";

const initialFilter: UserFilter = {
    search: "",
    role: null,
    apply: 0,
    classroom: "",
    checked: false,
};

const Users = () => {
    const [usersAdded, setUsersAdded] = useState(0);
    const [userAdded, setUserAdded] = useState(0);

    const [clearFilters, setClearFilters] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const { user } = useContext(UserContext);

    //
    const authorizedEditables: any[] = useMemo(
        () =>
            !user.classroom
                ? [
                      {
                          name: "classroom",
                          type: "autocomplete",
                          label: "Classroom",
                          autocomplete: {
                              labelKey: "class",
                              label: "Classroom",
                              api: "/class/all-classes-admin",
                              defaultValue: "classroom",
                          },
                      },
                  ]
                : [],
        [user]
    );

    //

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter(initialFilter);
    };

    const handleChange = (event: any) => {
        setFilter({ ...filter, checked: event.target.checked });
    };

    const theme = useTheme();

    return (
        <>
            <Authorize
                opName="CREATE USER"
                // fallback={
                //     <AuthorizeFallback message="You're not authorized to create a new employee" />
                // }
            >
                <CreateUser setUserAdded={setUserAdded} />
            </Authorize>
            <Grid container style={{ padding: "1rem" }} spacing={1}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        labelKey="name"
                        label="Role"
                        api="/role/all-roles"
                        setOutput={(r: any) =>
                            setFilter({
                                ...filter,
                                role: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                    {/* {user && !user.classroom && (
                    <>
                        <Autocomplete
                            labelKey="class"
                            label="Classroom"
                            api="/class/all-classes-admin"
                            setOutput={(r) =>
                                setFilter({
                                    ...filter,
                                    classroom: r?.id || null,
                                })
                            }
                            clear={clearFilters}
                            textFieldProps={{
                                variant: "outlined",
                            }}
                        />

                        <FormLabel>
                            No Classroom
                            <Checkbox
                                checked={filter?.checked}
                                onChange={handleChange}
                            />
                        </FormLabel>
                    </>
                )} */}
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            setFilter({ ...filter, apply: filter.apply + 1 })
                        }
                        style={{ height: "80%" }}
                        size="small"
                    >
                        {" "}
                        Apply Filter
                    </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <span
                style={{
                    borderBottom: `1px solid  ${theme.palette.divider}`,
                    display: "block",
                }}
            ></span>

            <RUDTable
                updatingAgents={[usersAdded, filter.apply, filter.search]}
                getter={(page, limit) =>
                    user && user.classroom
                        ? allUsers(page, limit, {
                              ...filter,
                              apply: undefined,
                              classroom: filter.classroom
                                  ? filter.classroom
                                  : null || filter.checked === true
                                  ? 0
                                  : null,

                              checked: undefined,
                          })
                        : allOrganizationAdminUsers(page, limit, {
                              ...filter,
                              apply: undefined,
                              classroom: filter.classroom
                                  ? filter.classroom
                                  : null || filter.checked === true
                                  ? 0
                                  : null,

                              checked: undefined,
                          })
                }
                deletor={(ids) => deleteUser(ids[0])}
                editor={editUser}
                ops={{
                    read: "READ USERS",
                    delete: "DELETE USERS",
                    edit: "EDIT USER",
                }}
                readables={{
                    id: "ID",
                    name: "Name",
                    email: "Email",
                    roleName: "Role",
                    classroomName: "Organization",
                    __verified: "Verified",
                }}
                editables={[
                    {
                        name: "name",
                        type: "textfield",
                        label: "Name",
                    },
                    {
                        name: "email",
                        type: "textfield",
                        label: "Email",
                        isRequired: true,
                    },

                    {
                        name: "role",
                        type: "autocomplete",
                        label: "Role",
                        isRequired: true,
                        autocomplete: {
                            labelKey: "name",
                            label: "Role",
                            api: "/role/all-roles",
                            defaultValue: "role",
                            // apiParams: { classroom: null },
                        },
                    },

                    {
                        name: "verified",
                        type: "autocomplete",
                        label: "Verification",
                        isRequired: true,
                        autocomplete: {
                            labelKey: "name",
                            label: "Verification",
                            defaultOptions: [
                                { id: true, name: "Verified" },
                                { id: false, name: "Unverified" },
                            ],
                            defaultValue: "_verified",
                        },
                    },

                    ...authorizedEditables,
                ]}
                rowsPreprocessor={(row) => ({
                    ...row,
                    roleName: row.role?.name || "-",
                    classroomName: row.classroom?.class || "No classroom",
                    __verified: row.baseUser.verified ? (
                        <Icon style={{ color: theme.palette.success.main }}>
                            <CheckCircle />
                        </Icon>
                    ) : (
                        <Icon style={{ color: theme.palette.error.main }}>
                            <Cancel />
                        </Icon>
                    ),
                    deletionId: row.id,
                    email: row.baseUser.email,
                    _verified: row.baseUser.verified
                        ? { id: true, name: "verified" }
                        : { id: false, name: "unverified" },
                })}
                opsMessages={{
                    read: "You're not authorized to read students",
                }}
                // selectedFilters={
                //   <SelectedFilters filter={filter} setFilter={setFilter} />
                // }
                filters={
                    <Filteration
                        handleClear={handleFilterClear}
                        filter={filter}
                        setFilter={setFilter}
                        // fields={[
                        //     <Box
                        //         display="flex"
                        //         flexDirection="column"
                        //         width="100%"
                        //         style={{ gap: theme.spacing(2) }}
                        //     >
                        //         <Autocomplete
                        //             labelKey="name"
                        //             label="Role"
                        //             api="/role/all-roles"
                        //             setOutput={(r: any) =>
                        //                 setFilter({
                        //                     ...filter,
                        //                     role: r?.id || null,
                        //                 })
                        //             }
                        //             clear={clearFilters}
                        //             textFieldProps={{ variant: "outlined" }}
                        //         />
                        //         {user && !user.classroom && (
                        //             <>
                        //                 <Autocomplete
                        //                     labelKey="class"
                        //                     label="Classroom"
                        //                     api="/class/all-classes-admin"
                        //                     setOutput={(r) =>
                        //                         setFilter({
                        //                             ...filter,
                        //                             classroom: r?.id || null,
                        //                         })
                        //                     }
                        //                     clear={clearFilters}
                        //                     textFieldProps={{
                        //                         variant: "outlined",
                        //                     }}
                        //                 />

                        //                 <FormLabel>
                        //                     No Classroom
                        //                     <Checkbox
                        //                         checked={filter?.checked}
                        //                         onChange={handleChange}
                        //                     />
                        //                 </FormLabel>
                        //             </>
                        //         )}
                        //     </Box>,
                        // ]}
                    />
                }
                storageKey="users"
            />
        </>
    );
};

export default Users;
