import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Divider,
    IconButton,
    LinearProgress,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { GetApp, InfoOutlined } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ChangeEvent, useState } from "react";
import * as XLSX from "xlsx";
import { DataImportService } from "../../services/DataImportService";
import Authorize from "../Authorize";
import { AuthorizeFallback } from "../AuthorizeFallback";

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        margin: theme.spacing(2),
    },
    content: {
        margin: theme.spacing(2),
    },
    fileSelection: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),

        margin: theme.spacing(1),
    },
    response: {
        margin: theme.spacing(1),
    },
}));

const readExcelFileAndCreateStudents = async (
    bin: string | ArrayBuffer | null | undefined,
    variant: any
): Promise<any[]> => {
    const wb = XLSX.read(bin, { type: "binary" });

    const sheets = wb.Sheets;

    let studentFailures: any[] = [],
        studentSuccesses: any[] = [],
        subjectsFailures: any[] = [],
        subjectsSuccesses: any[] = [];

    for (const sheet of Object.values(sheets)) {
        const json = XLSX.utils.sheet_to_json(sheet);

        console.log(json);

        const [data, err] = await DataImportService.createBulkStudents(
            json,
            variant
        );

        if (data) {
            studentSuccesses = [...studentSuccesses, data.successes].flat();
            studentFailures = [...studentFailures, data.failures].flat();
        }

        // const [subjectsResponseData, subjectsError] =
        //     await DataImportService.createBulkSubjectEnrollments(json);

        // if (subjectsResponseData) {
        //     subjectsFailures = [
        //         ...subjectsFailures,
        //         subjectsResponseData.failures,
        //     ];
        //     subjectsSuccesses = [
        //         ...subjectsSuccesses,
        //         subjectsResponseData.successes,
        //     ];
        // }

        // if (data) {
        //     studentFailures = [...studentFailures, ...data.failures];
        //     studentSuccesses = [...studentSuccesses, ...data.successes];
        // }
    }

    return [studentFailures, studentSuccesses];
};

export const DataImport = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState<"loading" | "initial" | "finished">(
        "initial"
    );
    const [fname, setFname] = useState("Select file");
    const [variant, setVariant] = useState<any>("employee");
    const [successes, setSucesses] = useState<any>([]);
    const [failures, setFailures] = useState<any>([]);
    const [showResponseDetails, setShowResponseDetails] = useState(false);
    const handleFileUpload = async (ev: ChangeEvent<HTMLInputElement>) => {
        setLoading("loading");
        const file = ev.target.files && ev.target.files[0];
        file && setFname(file.name);
        const reader = new FileReader();
        if (file) {
            reader.onload = async (evt) => {
                const binary = evt.target?.result;
                const [failures, _successes] =
                    await readExcelFileAndCreateStudents(binary, variant);
                setFailures(failures);
                setSucesses(_successes);
                setLoading("finished");
            };

            reader.readAsBinaryString(file);
        }
    };

    return (
        <Authorize
            opName="CREATE CLIENT"
            fallback={
                <AuthorizeFallback message="You're not authorized to import students" />
            }
        >
            <Card className={classes.cardRoot}>
                <CardContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography gutterBottom variant="h4">
                            Import Clients
                        </Typography>

                        <Button
                            variant="outlined"
                            startIcon={<GetApp />}
                            href="/templates/import-file.xlsx"
                            download
                        >
                            download template
                        </Button>
                    </Box>
                    <Divider />

                    <div className={classes.content}>
                        <div className={classes.fileSelection}>
                            <Button variant="outlined" component="label">
                                <input
                                    onChange={handleFileUpload}
                                    hidden
                                    type="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                />
                                Choose File
                            </Button>
                            <Typography
                                variant="caption"
                                style={{ fontWeight: "bold" }}
                            >
                                {fname}
                            </Typography>
                        </div>

                        <Typography
                            // variant="caption"
                            style={{ fontStyle: "normal" }}
                            gutterBottom
                        >
                            "The file format must be .xlsx and it must contain
                            valid data (email,password,clientType,city and
                            name). Also, ensure that the column names are:
                            'email', 'password', clientType, city and 'name'.
                            Optional data includes (phone, dob, education,
                            qualification, country)."
                        </Typography>

                        {loading === "loading" && <LinearProgress />}

                        <section
                            className={classes.response}
                            hidden={loading !== "finished"}
                        >
                            <Alert
                                severity="info"
                                action={
                                    <IconButton
                                        onClick={() => {
                                            setShowResponseDetails(
                                                !showResponseDetails
                                            );
                                        }}
                                    >
                                        <InfoOutlined fontSize="small" />
                                    </IconButton>
                                }
                            >
                                <AlertTitle>
                                    {`${successes!?.length} successes out of ${
                                        successes!?.length + failures!?.length
                                    } `}
                                </AlertTitle>

                                <Collapse in={showResponseDetails}>
                                    {successes?.map(
                                        (
                                            suc: {
                                                message: string;
                                                name: number | string;
                                            },
                                            index: number
                                        ) => (
                                            <Box key={index}>
                                                {`Name - (${suc.name}) - ${suc.message}`}
                                            </Box>
                                        )
                                    )}

                                    {failures.map(
                                        (
                                            err: {
                                                message: string;
                                                name: number | string;
                                            },
                                            index: number
                                        ) => (
                                            <Box key={index}>
                                                {`Name - (${err.name}) - ${err.message}`}
                                            </Box>
                                        )
                                    )}
                                </Collapse>
                            </Alert>
                        </section>
                    </div>
                </CardContent>
            </Card>
        </Authorize>
    );
};
export default DataImport;
