import axios from "axios";
import { Payment } from "../components/Payment/types";
import { baseAPI, notConnectedMessage } from "../components/constants";

type PaymentFilter = any;
type PaymentCollectionFilter = any;
type PaymentBreakdown = {
    breakDown: {
        heads: { id: number; name: string; amount: number }[];
        filter: { clientType: string; employee: string };
    };
};

export class PaymentHeadService {
    static url = baseAPI + "/payment/head";

    static getHeads = async (
        page = 1,
        limit = 10,
        filter?: any
    ): Promise<any[]> => {
        try {
            const response = await axios.get(PaymentHeadService.url, {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            return [response.data, null];
        } catch (err: any) {
            console.error("Get heads", err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static createHead = async (data: any) => {
        try {
            const response = await axios.post(PaymentHeadService.url, data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static createBulkHeads = async (data: any) => {
        try {
            const response = await axios.post(
                PaymentHeadService.url + "/bulk",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static updateHead = async (id: number, data: any) => {
        try {
            const response = await axios.patch(
                PaymentHeadService.url + "/",
                data,
                {
                    params: { id: id },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (error: any) {
            console.log(error, "erro");

            return [null, error.response?.data?.message || notConnectedMessage];
        }
    };

    static async deletePaymentHead(id: number) {
        try {
            const response = await axios.delete(
                PaymentHeadService.url + "/hard",
                {
                    params: { id: id },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}

export class PaymentService {
    static url = baseAPI + "/payment";

    static getPayments = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(PaymentService.url, {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static getTotalRecord = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(
                PaymentService.url + "/total-credit-debit",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static getPaymentForSuperAdmin = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(
                PaymentService.url + "/get-payment-superAdmin",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static getPaymentsToBePaid = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(PaymentService.url + "/group", {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static getBanksSummary = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(
                PaymentService.url + "/get-overallAmount-againtBank",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static getDetailCreditPaymentsAgainstBank = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(
                PaymentService.url + "/get-credit-payment-againt-bank",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static getPaymentsToBeAppliead = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(
                PaymentService.url + "/payment-to-apply",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static getPaymentsCollection = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(
                PaymentService.url + "/collection-payment",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };
    static getLastBalance = async (
        page = 1,
        limit = 10,
        filter: PaymentFilter = {}
    ) => {
        try {
            const response = await axios.get(
                PaymentService.url + "/check-last-balance",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static createPayment = async (data: Payment) => {
        try {
            const response = await axios.post(PaymentService.url, data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    };

    static createPaymentBulk = async (data: any) => {
        try {
            const response = await axios.post(
                PaymentService.url + "/bulk",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err || notConnectedMessage];
        }
    };

    static async updatePayment(id: number, data: any) {
        try {
            const response = await axios.patch(PaymentService.url + "/", data, {
                params: { id: id },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (error: any) {
            console.log(error);

            return [
                null,
                error.response?.data?.messaggeg || notConnectedMessage,
            ];
        }
    }

    static deletePayment = async (paymentId: number | string) => {
        try {
            const response = await axios.delete(PaymentService.url + "/soft", {
                params: { id: paymentId },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    };
}

export class PaymentCollectionService {
    static url = baseAPI + "/payment/collection";

    static getCollections = async (
        page = 1,
        limit = 10,
        filter: PaymentCollectionFilter = {}
    ) => {
        try {
            const response = await axios.get(PaymentCollectionService.url, {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static createCollection = async (data: any) => {
        try {
            const response = await axios.post(
                PaymentCollectionService.url,
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static verifyCollection = async (data: any) => {
        try {
            const response = await axios.post(
                PaymentCollectionService.url + "/verify",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };

    static rejectCollection = async (data: any) => {
        try {
            const response = await axios.post(
                PaymentCollectionService.url + "/reject",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };
}
