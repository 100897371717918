import { Box, IconButton } from "@material-ui/core";
import { EditOutlined, VisibilityOutlined } from "@material-ui/icons";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { RolesService } from "../../services/RolesService";
import Filteration from "../Filteration/Filteration";
import { RUDTable } from "../Table/RUDTable";
import UserContext from "../UserContext";
import { ShowSingleRole } from "./ShowSingleRole";

const initialFilter: RoleFilter = {
    search: "",
    apply: 0,
};

export const ShowRoles = () => {
    const [activeRoleId, setActiveRoleId] = useState(null);
    const [filter, setFilter] = useState(initialFilter);
    const { user } = useContext(UserContext);

    const history = useHistory();

    return (
        <Box style={{ padding: "8px" }}>
            <RUDTable
                getter={(page, limit) =>
                    RolesService.getRoles(
                        page,
                        limit,
                        {
                            ...filter,
                            apply: undefined,
                        },
                        !Boolean(user.classroom)
                    )
                }
                storageKey="showroles"
                // editor={async () => []}
                deletor={(ids) => RolesService.deleteRole(ids[0])}
                readables={{
                    id: "ID",
                    name: "Name",
                    description: "Description",
                    Details: "Details",
                    Edit: "Edit",
                }}
                ops={{
                    read: "READ ROLE",
                    edit: "EDIT ROLE",
                    delete: "DELETE ROLE",
                }}
                rowsPreprocessor={(row) => ({
                    ...row,
                    description: row.description ? row.description : "---",
                    deletionId: row.id,
                })}
                // editables={[{ label: "Name", name: "name", type: "textfield" }]}
                customCols={[
                    {
                        header: "Details",
                        content: (row) => (
                            <IconButton
                                onClick={() =>
                                    history.push(`/role/read/${row.id}`)
                                }
                            >
                                <VisibilityOutlined />
                            </IconButton>
                        ),
                    },
                    {
                        header: "Edit",
                        content: (row) => (
                            <IconButton
                                onClick={() => {
                                    history.push(`/role/update/${row.id}`);
                                }}
                            >
                                <EditOutlined />
                            </IconButton>
                        ),
                    },
                ]}
                filters={
                    <Filteration
                        handleClear={() => {}}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
                updatingAgents={[filter.search, user]}
            />

            <ShowSingleRole
                activeRoleId={activeRoleId}
                setActiveRoleId={setActiveRoleId}
            />
        </Box>
    );
};
export default ShowRoles;
