import { useTheme } from "@mui/material";
import Chart from "react-apexcharts";

type Props = {
    series: number[];
    labels: string[];
};

const Donut = ({ series, labels }: Props) => {
    const theme = useTheme();

    return (
        <Chart
            height="360"
            options={{
                labels: labels,
                plotOptions: {
                    pie: { expandOnClick: false, donut: { size: "50" } },
                },
                // colors: ["#d32f2f", "#388e3c"],
                colors: [
                    theme.palette.success.light,
                    theme.palette.error.light,
                    theme.palette.info.light,
                    theme.palette.warning.dark,
                    theme.palette.secondary.main,
                    theme.palette.grey[500],
                ],

                dataLabels: {
                    enabled: true,
                    style: {
                        // Change the color of the label text
                        fontSize: "14px", // Adjust font size if needed
                        fontFamily: "Arial, sans-serif",

                        colors: ["#ffffff"],
                    },
                },
                legend: {
                    show: true,
                    labels: {
                        colors: "#ffffff",
                    },
                },
            }}
            series={series}
            type="donut"
        />
    );
};

export default Donut;
