import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { Close, InsertDriveFileOutlined } from "@material-ui/icons";
import { ExpandCircleDown } from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import { Autocomplete } from "../../Autocomplete/Autocomplete";
import { PaymentHeadResponse } from "../Payments/CreatePayment/CreatePayment";
import SelectedHeadsNAmounts from "../Payments/SelectedHeadsNAmounts";

export const CollectionRow = ({
    collectee,
    collections,
    setCollections,
    checkAll,
    columnsVisibility,
    descTags,
    bank,
    headsData,
    descTagspagination,
    headsPagination,
    bankPagination,
}: any) => {
    const [isChecked, setIsChecked] = useState(checkAll);
    const [paymentHeads, setPaymentHeads] = useState<PaymentHeadResponse[]>([]);
    const [file, setFile] = useState<{
        file: File;
        src: string;
        openPreview: boolean;
    } | null>(null);

    const handleChange = (ev: ChangeEvent<any>, id: number) => {
        setIsChecked(true);
        setCollections(() => ({
            ...collections,
            [id]: {
                ...collections[id],
                [ev.target.name]: ev.target.value,
                checked: true,
            },
        }));
    };

    function handleCheck(
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setIsChecked(checked);
        setCollections((curr: any) => {
            const withCurrChecked = {
                ...curr,
                [collectee.id]: {
                    ...collections[collectee.id],
                    status: "credit",
                    client: collectee.client.id,
                    clientType: collectee?.client.employee.clientType?.id,
                    checked,
                },
            };

            return withCurrChecked;
        });
    }

    const handleFile = (ev: ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files && ev.target.files.length > 0) {
            const f = ev.target.files[0];
            setCollections(() => ({
                ...collections,
                [collectee.id]: {
                    ...collections[collectee.id],
                    file: f,
                    fileName: f.name,
                },
            }));

            setFile({
                file: f,
                src: URL.createObjectURL(f),
                openPreview: false,
            });
        }
    };

    useEffect(() => {
        setIsChecked(collections[collectee?.id]?.checked ?? false);
    }, [collections[collectee?.id]?.checked]);

    useEffect(() => {
        if (!collections[collectee.id]) return;
        setPaymentHeads(collections[collectee.id]?.heads ?? []);
    }, [collections[collectee.id]?.heads?.length]);

    return (
        <TableRow>
            <TableCell>
                <FormControl>
                    <Checkbox checked={isChecked} onChange={handleCheck} />
                </FormControl>
            </TableCell>

            {columnsVisibility?.Id && (
                <TableCell>{collectee.client.employee.id}</TableCell>
            )}
            {columnsVisibility.Client && (
                <TableCell>{collectee?.client?.employee?.name}</TableCell>
            )}

            {columnsVisibility.Balance && (
                <TableCell>{collectee.balance}</TableCell>
            )}

            {columnsVisibility.Name && (
                <TableCell>
                    <TextField
                        style={{ minWidth: "220px" }}
                        multiline
                        size="small"
                        name="name"
                        label="Name"
                        value={collections[collectee.id]?.name}
                        onChange={(ev) => handleChange(ev, collectee.id)}
                        variant="outlined"
                    />
                </TableCell>
            )}

            {columnsVisibility.Description && (
                <TableCell>
                    <TextField
                        style={{ minWidth: "280px" }}
                        multiline
                        size="small"
                        name="description"
                        label="Description"
                        value={collections[collectee.id]?.description}
                        onChange={(ev) => handleChange(ev, collectee.id)}
                        variant="outlined"
                    />
                </TableCell>
            )}

            {columnsVisibility.File && (
                <TableCell style={{ minWidth: "220px" }}>
                    <Tooltip title={file ? file.file.name : ""}>
                        <Typography variant="body2">
                            {file
                                ? file.file.name.length > 10
                                    ? file.file.name.slice(0, 10) + " ..."
                                    : file.file.name
                                : "Upload Receipt"}
                        </Typography>
                    </Tooltip>
                    <IconButton size="small" component="label">
                        <InsertDriveFileOutlined />
                        <input
                            hidden
                            type="file"
                            onChange={handleFile}
                            accept="image/*,application/pdf"
                        />
                    </IconButton>

                    {file ? (
                        <IconButton
                            size="small"
                            onClick={() =>
                                setFile({ ...file, openPreview: true })
                            }
                        >
                            <ExpandCircleDown />
                        </IconButton>
                    ) : null}

                    {file ? (
                        <IconButton onClick={() => setFile(null)} size="small">
                            <Close />
                        </IconButton>
                    ) : null}

                    <Dialog
                        fullWidth
                        open={Boolean(file?.openPreview)}
                        onClose={() =>
                            setFile({ ...file, openPreview: false } as any)
                        }
                    >
                        <DialogTitle>Preview</DialogTitle>

                        <DialogContent>
                            {file && (
                                <img
                                    style={{ borderRadius: "4px" }}
                                    width="100%"
                                    alt="Receipt preview"
                                    src={file.src}
                                />
                            )}
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() =>
                                    setFile({
                                        ...file,
                                        openPreview: false,
                                    } as any)
                                }
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </TableCell>
            )}

            <TableCell style={{ minWidth: "220px" }}>
                <Grid item xs={6} style={{ minWidth: "220px" }}>
                    <Autocomplete
                        setOutput={(bank: any) => {
                            setCollections((c: any) => ({
                                ...c,
                                [collectee.id]: {
                                    ...c[collectee.id],
                                    bank: bank?.id ?? "",
                                },
                            }));
                        }}
                        labelKey="name"
                        label="Bank"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                        defaultValue={
                            collections[collectee.id]?.bank
                                ? { id: collections[collectee.id]?.bank }
                                : undefined
                        }
                        clear={Boolean(collections[collectee.id]?.bank == "")}
                        defaultOptions={bank}
                        _page={bankPagination.page}
                        _limit={bankPagination.limit}
                    />
                </Grid>
            </TableCell>

            <TableCell style={{ minWidth: "220px" }}>
                <Autocomplete
                    defaultOptions={[
                        { label: "Payment", status: "payment", id: "payment" },
                        {
                            label: "Adjustment",
                            status: "adjustment",
                            id: "adjustment",
                        },

                        {
                            label: "Advance",
                            status: "advance",
                            id: "advance",
                        },
                    ]}
                    setOutput={(des: any) => {
                        // if (!des) return;

                        setCollections((c: any) => ({
                            ...c,
                            [collectee.id]: {
                                ...c[collectee.id],
                                type: des?.status ?? "",
                            },
                        }));
                    }}
                    labelKey="label"
                    label="Status"
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                    }}
                    defaultValue={
                        collections[collectee.id]?.type
                            ? { id: collections[collectee.id]?.type }
                            : undefined
                    }
                    clear={Boolean(collections[collectee.id]?.type == "")}
                />
            </TableCell>

            <TableCell style={{ minWidth: "220px" }}>
                <Autocomplete
                    setOutput={(_heads: PaymentHeadResponse[]) => {
                        setPaymentHeads(_heads);
                        setCollections(() => ({
                            ...collections,
                            [collectee.id]: {
                                ...collections[collectee.id],
                                ["heads"]: _heads,
                            },
                        }));
                    }}
                    multiple
                    labelKey="name"
                    label="Head"
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                    }}
                    defaultValue={
                        collections[collectee.id]?.heads
                            ? collections[collectee.id]?.heads
                            : undefined
                    }
                    defaultOptions={headsData}
                    _page={headsPagination.page}
                    _limit={headsPagination.limit}
                />

                {paymentHeads.length ? (
                    <Grid item xs={12}>
                        <SelectedHeadsNAmounts
                            heads={paymentHeads}
                            setHeads={setPaymentHeads}
                            collections={collections}
                            collecteeId={collectee.id}
                            setCollections={setCollections}
                        />
                    </Grid>
                ) : null}
            </TableCell>

            {columnsVisibility.DescriptionTags && (
                <TableCell style={{ minWidth: "220px" }}>
                    <Autocomplete
                        setOutput={(des: any) => {
                            setCollections(() => ({
                                ...collections,
                                [collectee.id]: {
                                    ...collections[collectee.id],
                                    ["descriptionTags"]: des.map(
                                        (item: any) => item
                                    ),
                                },
                            }));
                        }}
                        labelKey="name"
                        label="Description Tags"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        multiple
                        defaultValue={
                            collections[collectee.id]?.descriptionTags
                                ? collections[collectee.id]?.descriptionTags
                                : undefined
                        }
                        defaultOptions={descTags}
                        _page={descTagspagination.page}
                        _limit={descTagspagination.limit}
                    />
                </TableCell>
            )}
        </TableRow>
    );
};
