import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { Close, Edit, InsertDriveFileOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { ExpandCircleDown } from "@mui/icons-material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { awsSettings } from "../../constants";

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

const UpdateReceipt = ({ id }: any) => {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState<{
        file: any;
        src: string;
        openPreview: boolean;
    } | null>(null);
    const [fetchImage, setFectch] = useState(false);
    const [pic, setPic] = useState<any>();
    const [pdf, setPdf] = useState<any>();
    const [feedback, setFeedback] = useState<any>(initialFeedback);

    const handleFile = (ev: ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files && ev.target.files.length > 0) {
            const f = ev.target.files[0];

            // This number is the number of bytes in 1MB
            // if (f.size > 1048576) {
            //     setFeedback({
            //         status: "error",
            //         message: "Uploaded file must be less than 1MB",
            //     });
            //     return;
            // } else {
            //     setFeedback({ status: "idle", message: "" });
            // }

            setFile({
                file: f,
                src: URL.createObjectURL(f),
                openPreview: false,
            });
        }
    };

    const handleSubmit = async (ev: FormEvent<any>) => {
        ev.preventDefault();
        setFeedback((f: any) => ({ ...f, loading: true }));

        if (file && id) {
            // const key = `${awsSettings.paymentDir}/${id}`;
            if (file) {
                const extension = file.file.type.split("/")[1]; // e.g., 'jpg', 'png', 'pdf'
                const key = `${awsSettings.paymentDir}/${id}.${extension}`; // Add extension
                await S3Service.uploadToS3(key, file.file);
                const [data, err] = await S3Service.uploadToS3(key, file.file);

                if (data) {
                    setFeedback({
                        loading: false,
                        message: data,
                        hidden: false,
                        severity: "success",
                    });
                } else {
                    setFeedback({
                        loading: false,
                        message: err,
                        hidden: false,
                        severity: "error",
                    });
                }
            }
        }
    };

    const getImageOrPdf = async () => {
        const profilePicKeyBase = `${awsSettings.paymentDir}/${id}`;
        const possibleExtensions = ["jpg", "jpeg", "png", "pdf"]; // Supported extensions

        let found = false; // Track if a valid file is found

        // 1. Try fetching files with extensions
        for (const ext of possibleExtensions) {
            const keyWithExt = `${profilePicKeyBase}.${ext}`;
            const url = await S3Service.getS3ByKey(keyWithExt);

            if (url) {
                try {
                    const response = await fetch(url, { method: "GET" });
                    if (response.ok) {
                        const blob = await response.blob();
                        const blobURL = URL.createObjectURL(blob);

                        // 2. Set state based on the file type
                        if (ext === "pdf") {
                            setPdf(blobURL); // Store PDF URL
                        } else {
                            setPic(blobURL); // Store image URL
                        }
                        found = true;
                        break; // Exit loop once a valid file is found
                    }
                } catch (error) {
                    console.error(
                        `Error fetching file with extension ${ext}:`,
                        error
                    );
                }
            }
        }

        // 3. Fallback: Try fetching without extension (for old files)
        if (!found) {
            const urlWithoutExt = await S3Service.getS3ByKey(profilePicKeyBase);
            if (urlWithoutExt) {
                try {
                    const response = await fetch(urlWithoutExt, {
                        method: "GET",
                    });
                    if (response.ok) {
                        const blob = await response.blob();
                        const blobURL = URL.createObjectURL(blob);

                        // Assume it's an image if fetched without extension
                        setPic(blobURL);
                    }
                } catch (error) {
                    console.error(
                        "Error fetching file without extension:",
                        error
                    );
                }
            }
        }
    };

    // const getImage = async () => {
    //     const profilePicKey = awsSettings.paymentDir + "/" + id;
    //     const url = await S3Service.getS3ByKey(profilePicKey);

    //     if (url) {
    //         try {
    //             const response = await fetch(url, { method: "GET" });
    //             const blob = await response.blob();
    //             const blobURL = URL.createObjectURL(blob);
    //             setPic(blobURL);
    //         } catch (error) {}
    //     }
    // };

    useEffect(() => {
        if (fetchImage) {
            getImageOrPdf();
        }
    }, [fetchImage]);
    return (
        <>
            <IconButton
                onClick={() => {
                    setOpen(true);
                    setFectch(true);
                }}
                size="small"
            >
                {" "}
                <Edit fontSize="small" />
            </IconButton>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
                <section hidden={feedback.hidden} style={{ padding: "1rem" }}>
                    <Alert severity={feedback.severity as any}>
                        {feedback.message}
                    </Alert>
                </section>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                    }}
                >
                    {" "}
                    {pic && <img src={pic} width={"50%"} height={"50%"} />}
                    {pdf && <Button href={pdf}> Download pdf</Button>}
                </div>
                <Grid
                    container
                    component={"form"}
                    style={{ padding: "2rem" }}
                    onSubmit={handleSubmit}
                >
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "1rem",
                        }}
                    >
                        <Tooltip title={file ? file?.file?.name : ""}>
                            <Typography variant="body2">
                                {file
                                    ? file?.file?.name.length > 10
                                        ? file?.file?.name?.slice(0, 10) +
                                          " ..."
                                        : file?.file?.name
                                    : "Upload Receipt"}
                            </Typography>
                        </Tooltip>
                        <IconButton size="small" component="label">
                            <InsertDriveFileOutlined />
                            <input
                                hidden
                                type="file"
                                onChange={handleFile}
                                accept="image/*,application/pdf"
                            />
                        </IconButton>

                        {file ? (
                            <IconButton
                                size="small"
                                onClick={() =>
                                    setFile({ ...file, openPreview: true })
                                }
                            >
                                <ExpandCircleDown />
                            </IconButton>
                        ) : null}

                        {file ? (
                            <IconButton
                                onClick={() => setFile(null)}
                                size="small"
                            >
                                <Close />
                            </IconButton>
                        ) : null}
                    </Grid>

                    {file && (
                        <Grid item xs={12}>
                            <Button type="submit" variant="outlined" fullWidth>
                                Submit
                            </Button>
                        </Grid>
                    )}
                </Grid>

                <Dialog
                    fullWidth
                    open={Boolean(file?.openPreview)}
                    onClose={() =>
                        setFile({ ...file, openPreview: false } as any)
                    }
                >
                    <DialogTitle>Preview</DialogTitle>

                    <DialogContent>
                        {file && (
                            <img
                                style={{ borderRadius: "4px" }}
                                width="100%"
                                alt="Receipt preview"
                                src={file.src}
                            />
                        )}
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={() =>
                                setFile({
                                    ...file,
                                    openPreview: false,
                                } as any)
                            }
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </>
    );
};

export default UpdateReceipt;
