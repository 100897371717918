import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class CityService {
    static url = `${baseAPI}/city`;

    static async createCity(data: any) {
        try {
            const response = await axios.post(
                CityService.url + "/create",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getCity(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(CityService.url + "/get-All", {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async updateCity(id: number, data: any) {
        try {
            const response = await axios.patch(CityService.url + "/", data, {
                params: { id: id },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (error: any) {
            console.log(error);

            return [
                null,
                error.response?.data?.messaggeg || notConnectedMessage,
            ];
        }
    }

    static async deleteCity(id: number) {
        try {
            const response = await axios.delete(CityService.url, {
                params: { id: id },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
