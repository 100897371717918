import {
    Grid,
    TextField,
    createStyles,
    makeStyles,
    useTheme,
} from "@material-ui/core";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useContext,
    useRef,
    useState,
} from "react";
import UserContext from "../UserContext";

type Props = {
    fields?: JSX.Element[];
    filter: any;
    setFilter: Dispatch<SetStateAction<any>>;
    handleClear: () => void;
};

const useStyles = makeStyles((theme) => createStyles({}));

const Filteration = ({ fields, filter, setFilter, handleClear }: Props) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const anchorElementRef = useRef<null | HTMLButtonElement>(null);
    const theme = useTheme();
    const ref = useRef(0);

    const { user } = useContext(UserContext);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        ref.current++;
        const newRef = ref.current;
        const { value } = e.target;

        setSearchTerm(value);

        setTimeout(() => {
            if (newRef === ref.current) {
                setFilter({
                    ...filter,
                    search: value,
                    apply: filter?.apply ?? 0,
                });
                ref.current = 0;
            }
        }, 1000);
    };

    const handleApplyFilter = () => {
        setFilter({ ...filter, apply: filter.apply + 1 });

        // setOpen(false);
    };

    return (
        <Grid container spacing={2} alignItems="center">
            {Object.keys(filter).includes("search") && (
                <Grid item style={{ flex: "1" }}>
                    <TextField
                        fullWidth
                        placeholder="Search"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearch}
                        size="small"
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default Filteration;
