const filterToQuery = (filter) => {
    let qs = "";

    for (let [k, v] of Object.entries(filter)) {
        qs += `&${k}=${v}`;
    }
    return qs;
};

// console.log(filterToQuery({ name: "shhaid", id: 10 }))

export { filterToQuery };
