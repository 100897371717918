import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";
import { filterToQuery } from "./utils";

const endpoint = `${baseAPI}/class`;

export async function allClasses(page = 1, limit = 10, filter = {}) {
    filter = filterToQuery(filter);
    const api = `${endpoint}/all-classes?page=${page}&limit=${limit}${filter}`;
    try {
        const response = await axios.get(api, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        });
        console.log(response.data);
        return [response.data, null];
    } catch (err) {
        console.log(err);

        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export async function allClassesAdmin(page = 1, limit = 10, filter = {}) {
    const api = `${endpoint}/all-classes-admin`;

    try {
        const response = await axios.get(api, {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
            params: { page, limit, ...filter },
        });

        return [response.data, null];
    } catch (err) {
        console.log(err);

        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export async function createNewClassroom(newClassroom) {
    const api = `${endpoint}/create`;

    try {
        const response = await axios.post(api, newClassroom, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
        });
        console.log(response);
        return [response.data, null];
    } catch (err) {
        console.log(err);
        if (err.response) {
            return [[], err.response?.data?.message || notConnectedMessage];
        }
    }
}

export const deleteClassroom = async (id) => {
    const url = `${baseAPI}/class/soft`;
    try {
        const response = await axios.post(
            url,
            {},
            { withCredentials: true, params: { id } }
        );

        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export async function assignSubjectToEmployee(subject) {
    const api = `${endpoint}/assigned-subject/assign`;

    try {
        const response = await axios.post(api, subject, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
        });
        console.log(response);

        return [response.data, null];
    } catch (err) {
        err.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
}

export async function allClassesCount() {
    return (
        await axios.get(`${endpoint}/all-classes-count`, {
            withCredentials: true,
        })
    ).data.count;
}

export async function allClassesAdminCount() {
    return (
        await axios.get(`${endpoint}/all-classes-admin-count`, {
            withCredentials: true,
        })
    ).data;
}

export const addPrivateClassroom = async (token) => {
    try {
        const api = `${endpoint}/private/create`;
        const response = await axios.post(
            api,
            { token },
            { withCredentials: true }
        );
        return [response.data, null];
    } catch (err) {
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const deleteClassrooms = async (classroomIds) => {
    try {
        const response = await axios.delete(`${baseAPI}/class`, {
            data: { classroomIds },
            withCredentials: true,
        });
        return [response.data, null];
    } catch (err) {
        console.log(err);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};

export const editClassroom = async (id, newClassroom) => {
    try {
        const api = `${endpoint}/`;
        const response = await axios.patch(api, newClassroom, {
            withCredentials: true,
            params: { id },
        });
        return [response.data, null];
    } catch (err) {
        console.log(err.response);
        return [null, err.response?.data?.message || notConnectedMessage];
    }
};
