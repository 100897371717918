import {
    Box,
    Chip,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { currencyFormatter } from "../constants";

const PaymentHeadView = ({ row, show }: any) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const amount = row.heads.reduce(
        (prev: any, curr: any) =>
            prev + +curr.head.isAmountEditable ? curr.amount : curr.head.amount,
        0
    );

    return (
        <>
            {show ? (
                <>
                    {currencyFormatter(amount)}
                    <IconButton onClick={() => setOpen(!open)}>
                        <InfoIcon fontSize="small" />
                    </IconButton>

                    <Collapse in={open}>
                        {row.heads.map((singleHead: any) => (
                            <Box
                                key={singleHead.id}
                                display="flex"
                                alignItems="center"
                            >
                                <span
                                    style={{
                                        minWidth: "15ch",
                                    }}
                                >
                                    {/* { head.isAmocurrencyFormatter(head.head.amount)} */}
                                    {singleHead.head.isAmountEditable
                                        ? currencyFormatter(singleHead.amount)
                                        : currencyFormatter(
                                              singleHead.head.amount
                                          )}
                                </span>

                                <Typography variant="caption">
                                    {singleHead.head.name}
                                </Typography>
                            </Box>
                        ))}
                    </Collapse>
                </>
            ) : (
                row.heads.map((item: any) => {
                    return (
                        <Tooltip
                            title={`Amount :
                            ${
                                item.head.isAmountEditable
                                    ? item.amount
                                    : item.head.amount
                            } `}
                        >
                            <Chip
                                label={item.head.name}
                                size="small"
                                style={{
                                    color: theme.palette.primary.contrastText,
                                    backgroundColor: theme.palette.success.main,
                                }}
                            />
                        </Tooltip>
                    );
                })
            )}
        </>
    );
};

export default PaymentHeadView;
