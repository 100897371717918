import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { CityService } from "../../services/CityService";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
    form: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            minWidth: "30%",
            margin: theme.spacing(1),
        },
    },
}));

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

const AddCity = ({ setCityAdded }: any) => {
    const [name, setName] = useState("");
    const classes = useStyles();

    const [feedback, setFeedback] = useState(initialFeedback);

    async function handleSubmit(e: any) {
        e.preventDefault();
        setFeedback({ ...initialFeedback, loading: true });

        const [data, err] = await CityService.createCity({
            name: name,
        });

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                hidden: false,
                severity: "success",
            });
            setCityAdded((n: number) => n + 1);
        } else {
            setFeedback({
                loading: false,
                message: err,
                hidden: false,
                severity: "error",
            });
        }
    }
    return (
        <Accordion className={classes.root}>
            <AccordionSummary>
                <Typography variant="h5">Create City</Typography>
            </AccordionSummary>
            <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
            >
                {feedback.loading && <LinearProgress />}
                <section
                    hidden={feedback.hidden}
                    style={{ paddingBottom: "1rem" }}
                >
                    <Alert severity={feedback.severity as any}>
                        {feedback.message}
                    </Alert>
                </section>

                <Grid
                    container
                    spacing={2}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Grid item xs={12} md={4} lg={3}>
                        <TextField
                            name="name"
                            value={name}
                            onChange={(ev) => setName(ev.target.value)}
                            label="City Name"
                            required
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <Button
                            style={{ height: "100%" }}
                            fullWidth
                            color="primary"
                            variant="outlined"
                            type="submit"
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1.1rem" />
                                ) : (
                                    <Add />
                                )
                            }
                        >
                            Create City
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default AddCity;
