import {
    Box,
    Button,
    Checkbox,
    Grid,
    Hidden,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import { ViewColumn, ViewColumnOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { PaymentService } from "../../services/PaymentService";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import { dateFormatter } from "../constants";
import PrintTable from "../export/PrintTable";
import { ReportHeader } from "../settings/Header/ReportHeader";
import UserContext from "../UserContext";

const PaymentAgaintBank = () => {
    const [filter, setFilter] = useState({
        search: "",
        seachForShowing: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
        city: "",
    });
    const [dataWithBalance, setDataWithBalance] = useState([]);

    const [showColumns, setShowColumns] = useState(false);
    const [_updateSelected, setUpdateSelected] = useState<any>();
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);

    const [columnsVisibility, setColumnsVisibility] = useState<any>({
        SrNo: true,
        Client: true,
        Date: true,
        Credit: true,
        Debit: true,
        Balance: true,
        Description: true,
    });
    const printRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();

    const [printMode, setPrintMode] = useState(false);
    const [paymentToBePaid, setPaymentToBePaid] = useState<any>();
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 10,
    });
    const { settings } = useContext(UserContext);
    const [clearFilters, setClearFilters] = useState(false);
    const theme = useTheme();
    const [balanceSum, setBalanceSum] = useState<{ [page: number]: number }>(
        {}
    );

    const [trialBalance, setTrialBalance] = useState<any>();
    const ref = useRef(0);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        ref.current++;
        const newRef = ref.current;
        const { value } = e.target;

        setFilter({ ...filter, seachForShowing: value });

        setTimeout(() => {
            if (newRef === ref.current) {
                setFilter((curr: any) => ({
                    ...curr,
                    ["search"]: value,
                }));

                ref.current = 0;
            }
        }, 1000);
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            localStorage.setItem(
                "paymentAgainstBank",
                JSON.stringify(newVisibility)
            );

            return newVisibility;
        });
    };

    const getTrialBalance = async () => {
        const [data, err] =
            await PaymentService.getDetailCreditPaymentsAgainstBank(
                pagination.page + 1,
                pagination.limit,
                {
                    bank: filter.bank ? filter.bank : null,
                    startDate: filter.startDate ? filter.startDate : null,
                    endDate: filter.endDate ? filter.endDate : null,
                }
            );

        if (data?.rows?.length) {
            setTrialBalance(data?.rows);

            const rowsCreditSum = data.rows.reduce(
                (prev: number, curr: any) => {
                    return (prev + curr.credit) as number;
                },
                0
            ) as number;

            setBalanceSum((curr) => ({
                ...curr,
                [pagination.page]: rowsCreditSum,
            }));

            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
        } else {
            setPaymentToBePaid([]);
            setTrialBalance([]);
        }
    };

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            seachForShowing: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
            city: "",
        });

        setCount(count + 1);
    };

    useEffect(() => {
        getTrialBalance();
    }, [filter.apply, filter.search, count, pagination.page, pagination.limit]);

    useEffect(() => {
        const savedColumns = localStorage.getItem("paymentAgainstBank");

        if (savedColumns) {
            setColumnsVisibility(JSON.parse(savedColumns));
        } else {
            setColumnsVisibility({
                SrNo: true,
                Client: true,
                Date: true,
                Credit: true,
                Debit: true,
                Balance: true,
                Description: true,
            });
        }
    }, []);

    useEffect(() => {
        // Get the last balance from the previous page, default to 0 if not found
        const lastPageBalance = balanceSum[pagination.page - 1] || 0;
        let runningBalance = lastPageBalance; // Start from the last page's balance

        const updatedRows = trialBalance?.map((row: any) => {
            // Calculate the new balance by adding credit and subtracting debit
            runningBalance += row.credit - row.debit;

            return {
                ...row,
                balance: runningBalance, // Store the running balance for each row
            };
        });

        setDataWithBalance(updatedRows); // Update the state with the new balances

        // Avoid redundant updates to balanceSum
        setBalanceSum((prev) => {
            if (prev[pagination.page] === runningBalance) return prev; // Prevent unnecessary state updates
            return {
                ...prev,
                [pagination.page]: runningBalance, // Store the last balance for this page
            };
        });
    }, [trialBalance, pagination.page]); // Remove `balanceSum` from dependencies

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        if (Boolean(id)) {
            setFilter(
                (f) =>
                    ({
                        ...f,
                        bank: id,
                        apply: filter.apply + 1,
                    } as any)
            );
        }
    }, [location]);

    return (
        <Grid container spacing={1} style={{ padding: "2rem" }}>
            <Grid container spacing={1} style={{ padding: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/payment/method/get-All"
                        setOutput={(bank: any) => {
                            if (!bank) return;
                            setFilter({ ...filter, bank: bank.id });
                        }}
                        labelKey="name"
                        label="Bank"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                            // required: true,
                        }}
                        clear={clearFilters}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="From Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                startDate: e.target.value,
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextField
                        type="date"
                        size="small"
                        fullWidth
                        label="To Date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                endDate: e.target.value,
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() =>
                            setFilter({
                                ...filter,
                                apply: filter.apply + 1,
                            })
                        }
                        size="small"
                        style={{ height: "80%" }}
                    >
                        Apply filter
                    </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                style={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            ></Grid>

            <Grid
                container
                style={{ padding: "2rem", justifyContent: "space-between" }}
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    {Object.keys(filter).includes("search") && (
                        <Grid item style={{ flex: "1" }}>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                variant="outlined"
                                value={filter.seachForShowing}
                                onChange={handleSearch}
                                label="Search"
                                size="small"
                            />
                        </Grid>
                    )}

                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem key={col} style={{ paddingLeft: 0 }}>
                                <Checkbox
                                    size="small"
                                    checked={columnsVisibility[col]} // Checked if the column is visible
                                    onChange={() =>
                                        handleColumnVisibilityChange(col)
                                    }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Hidden smDown>
                            <PrintTable
                                componentRef={printRef}
                                setPrintMode={setPrintMode}
                            />
                        </Hidden>

                        <TablePagination
                            component="div"
                            onPageChange={(ev, page) =>
                                setPagination({ ...pagination, page: page })
                            }
                            onRowsPerPageChange={(ev) =>
                                setPagination({
                                    ...pagination,
                                    limit: parseInt(ev.target.value),
                                    page: 0,
                                })
                            }
                            count={pagination.count}
                            page={pagination.page}
                            rowsPerPage={pagination.limit}
                        />
                    </Box>
                </Grid>
            </Grid>

            {trialBalance && trialBalance.length > 0 ? (
                <Grid item xs={12} ref={printRef}>
                    {printMode && (
                        <ReportHeader
                            orgInfo={(() => {
                                let reportsHeader = settings["reports-header"];
                                try {
                                    reportsHeader = JSON.parse(reportsHeader);
                                } catch (e) {
                                    reportsHeader = null;
                                }

                                return (
                                    reportsHeader || {
                                        name: {
                                            value: "Account Book",
                                            selected: true,
                                        },
                                        email: {
                                            value: "",
                                            selected: false,
                                        },
                                        contactNo: {
                                            value: "",
                                            selected: false,
                                        },
                                        address: {
                                            value: "",
                                            selected: false,
                                        },
                                        logoPos: {
                                            value: "left",
                                            selected: false,
                                        },
                                    }
                                );
                            })()}
                        />
                    )}
                    <TableContainer>
                        <Table size="medium">
                            <TableHead>
                                <TableRow style={{ whiteSpace: "nowrap" }}>
                                    {columnsVisibility.SrNo && (
                                        <TableCell>SR No</TableCell>
                                    )}

                                    {columnsVisibility.Date && (
                                        <TableCell>Date</TableCell>
                                    )}

                                    {columnsVisibility.Client && (
                                        <TableCell>Client</TableCell>
                                    )}
                                    {columnsVisibility.Credit && (
                                        <TableCell>Credit</TableCell>
                                    )}

                                    {columnsVisibility.Debit && (
                                        <TableCell>Debit</TableCell>
                                    )}

                                    {columnsVisibility.Balance && (
                                        <TableCell>Balance</TableCell>
                                    )}

                                    {columnsVisibility.Description && (
                                        <TableCell>Description</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {dataWithBalance?.map(
                                    (item: any, idx: number) => (
                                        <TableRow>
                                            {columnsVisibility.SrNo && (
                                                <TableCell>{idx + 1}</TableCell>
                                            )}

                                            {columnsVisibility.Date && (
                                                <TableCell>
                                                    {dateFormatter.format(
                                                        new Date(item.createdAt)
                                                    )}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Client && (
                                                <TableCell>
                                                    {item.client.employee.name}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Credit && (
                                                <TableCell>
                                                    {item.credit}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Debit && (
                                                <TableCell>
                                                    {item.debit}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Balance && (
                                                <TableCell>
                                                    {item.balance}
                                                </TableCell>
                                            )}

                                            {columnsVisibility.Description && (
                                                <TableCell>
                                                    {item.description ? (
                                                        item.description
                                                    ) : (
                                                        <Typography
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .divider,
                                                            }}
                                                        >
                                                            N/A
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ) : (
                <Alert severity="info" style={{ width: "100%" }}>
                    please select a bank in order to show data
                </Alert>
            )}
        </Grid>
    );
};

export default PaymentAgaintBank;
