import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { notConnectedMessage } from "../../components/constants";

export enum APIRequestStatus {
    "idle" = "idle",
    "loading" = "loading",
    "success" = "success",
    "error" = "error",
}

export const useGetAPI = (
    url: string,
    page: number = 1,
    limit: number = 10,
    filter: any = {},
    triggers: any[] = [],
    deps: any[] = [],
    postProcessor: (rows: any[]) => any = (rows) => rows
): [any, number, APIRequestStatus, string] => {
    const [rows, setRows] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [status, setStatus] = useState<APIRequestStatus>(
        APIRequestStatus.idle
    );
    const [message, setMessage] = useState(notConnectedMessage);
    const timeout = useRef<any>(0);

    const getRows = async () => {
        timeout.current = setTimeout(() => {
            setStatus(APIRequestStatus.loading);
        }, 500);

        try {
            const response = await axios.get(url, {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            setRows(postProcessor(response.data.rows));
            setCount(response.data.count);
            setMessage(response.data.message);

            clearTimeout(timeout.current);
            setStatus(APIRequestStatus.success);
        } catch (err: any) {
            clearTimeout(timeout.current);
            setStatus(APIRequestStatus.error);
            setMessage(err.response?.data?.message || notConnectedMessage);
            console.log(err);
        }
    };

    useEffect(() => {
        if (deps.every((d) => Boolean(d))) {
            getRows();
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [...triggers]);

    return [rows, count, status, message];
};

export const usePatchAPI = (
    url: string
): [(data: any, params: any) => void, any, APIRequestStatus, string] => {
    const [trigger, setTrigger] = useState(0);
    const [status, setStatus] = useState<APIRequestStatus>(
        APIRequestStatus.idle
    );
    const [message, setMessage] = useState(notConnectedMessage);
    const [response, setResponse] = useState(null);
    const [_data, setData] = useState(null);
    const [params, setParams] = useState(null);

    const patch = async () => {
        setStatus(APIRequestStatus.loading);
        try {
            const response = await axios.patch(url, _data, {
                withCredentials: true,
                params,
            });
            setResponse(response.data);
            setStatus(APIRequestStatus.success);
            setMessage(response.data.message);
        } catch (err: any) {
            setMessage(err.response?.data?.message || notConnectedMessage);
            setStatus(APIRequestStatus.error);
        }
    };

    useEffect(() => {
        if (trigger && _data !== null && params !== null) {
            patch();
        }
    }, [trigger]);

    return [
        (data: any = {}, params: any = {}) => {
            setData(data);
            setParams(params);
            setTrigger((t) => t + 1);
        },
        response,
        status,
        message,
    ];
};

export const usePostAPI = (
    url: string
): [(data: any, params: any) => void, any, APIRequestStatus, string] => {
    const [trigger, setTrigger] = useState(0);
    const [status, setStatus] = useState<APIRequestStatus>(
        APIRequestStatus.idle
    );
    const [message, setMessage] = useState(notConnectedMessage);
    const [response, setResponse] = useState(null);
    const [_data, setData] = useState(null);
    const [params, setParams] = useState(null);

    const post = async () => {
        setStatus(APIRequestStatus.loading);
        try {
            console.log(_data);
            const response = await axios.post(url, _data, {
                withCredentials: true,
                params,
            });
            console.log(response);
            setResponse(response.data);
            setStatus(APIRequestStatus.success);
            setMessage(response.data.message);
        } catch (err: any) {
            console.log(err);
            setMessage(err.response?.data?.message || notConnectedMessage);
            setStatus(APIRequestStatus.error);
            setResponse(err.response.data);
        }
    };

    useEffect(() => {
        if (trigger && _data !== null && params !== null) {
            post();
        }
    }, [trigger]);

    return [
        (data: any = {}, params: any = {}) => {
            setData(data);
            setParams(params);
            setTrigger((t) => t + 1);
        },
        response,
        status,
        message,
    ];
};

export const formatNumber = (
    number: string | number,
    opts?: { delimiter?: string; prefix?: string }
) => {
    const stringNumber = number + "";
    let splitted = "";

    let i = 0;

    stringNumber
        .split("")
        .reverse()
        .forEach((val) => {
            if (i % 3 === 0) {
                if (!(i === 0)) {
                    splitted = (opts?.delimiter || ",") + splitted;
                }
            }
            splitted = val + splitted;
            i++;
        });

    return (opts?.prefix || "") + splitted;
};
const oneToTwenty = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
];

const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
];

export const numInWords: any = (numStr: string, existing = "") => {
    let inWords = "";

    if (numStr.length === 4) {
        return numInWords(
            numStr.slice(1),
            existing + oneToTwenty[numStr[0] as any] + " thousand "
        );
    } else if (numStr.length === 3) {
        if (numStr[0] === "0") {
            return numInWords(numStr.slice(1), existing);
        }
        return numInWords(
            numStr.slice(1),
            existing + oneToTwenty[numStr[0] as any] + " hundred "
        );
    } else if (numStr.length === 2) {
        if (parseInt(numStr) > 20) {
            return (
                existing +
                tens[numStr[0] as any] +
                " " +
                oneToTwenty[numStr[1] as any]
            );
        } else {
            return existing + oneToTwenty[parseInt(numStr)];
        }
    } else if (numStr.length === 1) {
        return existing + oneToTwenty[numStr as any];
    }

    return inWords;
};
