import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
    useMediaQuery,
} from "@material-ui/core";
import {
    Add,
    AddOutlined,
    InfoOutlined,
    KeyboardArrowDownOutlined,
    Remove,
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import {
    ChangeEvent,
    Dispatch,
    FormEvent,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { PaymentHeadService } from "../../../services/PaymentService";
import UserContext from "../../UserContext";
import { lastIndexInObj } from "../../constants";

export enum PaymentHeadVisibility {
    superadmin = "superadmin",
    organization = "organization",
    publicTrainer = "publicTrainer",
}

const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

type PaymentHead = {
    name: string;
    visibility: PaymentHeadVisibility | null;
    control: number | null;
    amount: number;
    isAmountEditable: boolean;
};

const initialHeads: { [key: number]: PaymentHead } = {
    0: {
        name: "",
        visibility: null,
        control: null,
        amount: 0,
        isAmountEditable: false,
    },
};

type Props = {
    setChanges?: Dispatch<SetStateAction<boolean>>;
    updateMode?: any;
    row?: any;
};

export const CreateHead = ({ setChanges, updateMode, row }: Props) => {
    const [heads, setHeads] = useState(initialHeads);
    const [updateHead, setUpdatedHeads] = useState<any>({
        name: "",
        amount: "",
        isAmountEditable: false,
    });

    const [showResponseDetails, setShowResponseDetails] = useState(false);
    const [feedback, setFeedback] = useState(initialFeedback);
    const [response, setResponse] = useState<{
        successes: { id: number; message: string }[];
        failures: { id: number; message: string }[];
    }>({ successes: [], failures: [] });

    const classes = useStyles();
    const { user } = useContext(UserContext);
    const isLowerThanSM = useMediaQuery((theme: any) =>
        theme.breakpoints.down("sm")
    );

    //
    const handleRow = (variant: string, rowId = 0) => {
        if (variant === "add") {
            const newEntryId = lastIndexInObj(heads) + 1;

            setHeads((h) => ({
                ...h,
                [newEntryId]: {
                    amount: 0,
                    control: null,
                    isAmountEditable: false,
                    name: "",
                    visibility: null,
                },
            }));
        } else if (variant === "remove") {
            const updatedStock = Object.fromEntries(
                Object.entries(heads).filter(([id]) => parseInt(id) !== rowId)
            );

            setHeads(updatedStock);
        }
    };

    const handleUpdateRow = (variant: string, rowId = 0) => {
        if (variant === "add") {
            const newEntryId = lastIndexInObj(heads) + 1;

            setUpdatedHeads((h: any) => ({
                ...h,
                [newEntryId]: {
                    amount: 0,
                    control: null,
                    isAmountEditable: false,
                    name: "",
                    visibility: null,
                },
            }));
        } else if (variant === "remove") {
            const updatedStock = Object.fromEntries(
                Object.entries(heads).filter(([id]) => parseInt(id) !== rowId)
            );

            setUpdatedHeads(updatedStock);
        }
    };

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        const [index, fieldName] = ev.target.name.split("-");

        setHeads((h) => ({
            ...h,
            [index]: { ...h[parseInt(index)], [fieldName]: ev.target.value },
        }));
    };

    const handleCreate = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const [data, err] = await PaymentHeadService.createBulkHeads({
            heads: Object.values(heads).map((h) => ({
                ...h,
                visibility: !user.classroom ? "superadmin" : "organization",
                control: user.classroom?.id ?? null,
            })),
        });

        if (data) {
            setFeedback({
                loading: false,
                hidden: false,
                severity: "success",
                message: data.message || "Payment head created successfully",
            });
            setResponse({ successes: data.successes, failures: data.failures });
            if (setChanges) {
                setChanges((c) => !c);
            }
        } else {
            setResponse({ successes: data.successes, failures: data.failures });
            setFeedback({
                loading: false,
                hidden: false,
                severity: "error",
                message: err || "Failed to create the payment head",
            });
        }
    };

    const handleUpdateHead = async (ev: any) => {
        ev.preventDefault();
        setFeedback({ ...initialFeedback, loading: true });
        const [data, err] = await PaymentHeadService.updateHead(row.id, {
            amount: updateHead.amount,
            isAmountEditable: updateHead.isAmountEditable,
            name: updateHead.name,
        });

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                hidden: false,
                severity: "success",
            });
        } else {
            console.log(err);
            setFeedback({
                loading: false,
                message: err,
                hidden: false,
                severity: "error",
            });
        }
    };

    useEffect(() => {
        if (!row) return;

        setUpdatedHeads({
            name: row.name,
            amount: row.amount,
            isAmountEditable: row.isAmountEditable,
        });
    }, [updateMode]);

    return (
        <>
            {" "}
            {!updateMode ? (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<KeyboardArrowDownOutlined />}
                    >
                        <Typography variant="h5">Create Heads</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        style={
                            isLowerThanSM
                                ? {}
                                : { maxWidth: "60%", margin: "auto" }
                        }
                    >
                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            component="form"
                            onSubmit={handleCreate}
                            justifyContent="center"
                        >
                            {!feedback.hidden && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: "1rem" }}
                                >
                                    <Alert
                                        severity={feedback.severity as any}
                                        action={
                                            <IconButton
                                                onClick={() => {
                                                    setShowResponseDetails(
                                                        !showResponseDetails
                                                    );
                                                }}
                                            >
                                                <InfoOutlined fontSize="small" />
                                            </IconButton>
                                        }
                                    >
                                        <AlertTitle>
                                            {`${
                                                response.successes.length
                                            } successes out of ${
                                                response.successes.length +
                                                response.failures.length
                                            } `}
                                        </AlertTitle>

                                        <Collapse in={showResponseDetails}>
                                            {response.successes.map(
                                                (
                                                    suc: {
                                                        message: string;
                                                        id: number | string;
                                                    },
                                                    index: number
                                                ) => (
                                                    <Box key={index}>
                                                        {`ID (${suc.id}) - ${suc.message}`}
                                                    </Box>
                                                )
                                            )}

                                            {response.failures.map(
                                                (
                                                    err: {
                                                        message: string;
                                                        id: number | string;
                                                    },
                                                    index: number
                                                ) => (
                                                    <Box key={index}>
                                                        {`ID (${err.id}) - ${err.message}`}
                                                    </Box>
                                                )
                                            )}
                                        </Collapse>
                                    </Alert>
                                </Grid>
                            )}

                            {Object.entries(heads).map(([index, head]) => (
                                <Grid
                                    key={`chap${index}`}
                                    item
                                    xs={12}
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                >
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <TextField
                                            required
                                            fullWidth
                                            variant="outlined"
                                            label="Name"
                                            name={`${index}-name`}
                                            value={head.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={3}>
                                        <TextField
                                            required
                                            fullWidth
                                            variant="outlined"
                                            label="Amount"
                                            type="number"
                                            name={`${index}-amount`}
                                            inputProps={{ min: "0" }}
                                            value={head.amount}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} lg={3}>
                                        <Box
                                            className={classes.checkboxWrapper}
                                            sx={{ height: "100%" }}
                                        >
                                            <FormControl>
                                                <FormControlLabel
                                                    label="Editable"
                                                    name="isAmountEditable"
                                                    control={
                                                        <Checkbox
                                                            value={
                                                                head.isAmountEditable
                                                            }
                                                            onChange={(
                                                                ev,
                                                                checked
                                                            ) =>
                                                                setHeads(
                                                                    (h) => ({
                                                                        ...h,
                                                                        [index]:
                                                                            {
                                                                                ...h[
                                                                                    parseInt(
                                                                                        index
                                                                                    )
                                                                                ],
                                                                                isAmountEditable:
                                                                                    checked,
                                                                            },
                                                                    })
                                                                )
                                                            }
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    <Grid item xs="auto">
                                        {parseInt(index) === 0 ? (
                                            <Tooltip title="Add Head">
                                                <IconButton
                                                    onClick={() =>
                                                        handleRow("add")
                                                    }
                                                >
                                                    <Add />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Remove Head">
                                                <IconButton
                                                    onClick={() =>
                                                        handleRow(
                                                            "remove",
                                                            parseInt(index)
                                                        )
                                                    }
                                                >
                                                    <Remove />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}

                            <Grid item>
                                <Button
                                    fullWidth
                                    disabled={feedback.loading}
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    endIcon={
                                        feedback.loading ? (
                                            <CircularProgress
                                                color="primary"
                                                size="1rem"
                                            />
                                        ) : (
                                            <AddOutlined />
                                        )
                                    }
                                >
                                    Create&nbsp;Head
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ) : (
                <Grid
                    container
                    spacing={1}
                    component="form"
                    onSubmit={handleUpdateHead}
                >
                    {feedback.loading && <LinearProgress />}
                    <section
                        hidden={feedback.hidden}
                        style={{ paddingBottom: "1rem", width: "100%" }}
                    >
                        <Alert severity={feedback.severity as any}>
                            {feedback.message}
                        </Alert>
                    </section>

                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                label="Name"
                                name={updateHead.name}
                                value={updateHead.name}
                                onChange={(ev) =>
                                    setUpdatedHeads({
                                        ...updateHead,
                                        name: ev.target.value,
                                    })
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                variant="outlined"
                                label="Amount"
                                type="number"
                                name={updateHead.amount}
                                inputProps={{ min: "0" }}
                                value={updateHead.amount}
                                onChange={(ev) =>
                                    setUpdatedHeads({
                                        ...updateHead,
                                        amount: ev.target.value,
                                    })
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                className={classes.checkboxWrapper}
                                sx={{ height: "100%" }}
                            >
                                <FormControl>
                                    <FormControlLabel
                                        label="Editable"
                                        name={updateHead.isAmountEditable}
                                        control={
                                            <Checkbox
                                                value={
                                                    updateHead.isAmountEditable
                                                }
                                                onChange={(ev, checked) =>
                                                    setUpdatedHeads(
                                                        (curr: any) => {
                                                            return {
                                                                ...curr,
                                                                isAmountEditable:
                                                                    checked,
                                                            };
                                                        }
                                                    )
                                                }
                                                color="primary"
                                                defaultChecked={
                                                    row?.isAmountEditable
                                                }
                                            />
                                        }
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            disabled={feedback.loading}
                            type="submit"
                            variant="outlined"
                            color="primary"
                        >
                            Update&nbsp;Head
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    checkboxWrapper: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        height: "100%",
        width: "100%",
        padding: theme.spacing(0.75, 1.5),
    },
}));
