import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    FormControl,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import {
    InfoOutlined,
    ViewColumn,
    ViewColumnOutlined,
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { DescriptionTagsService } from "../../../services/DescriptionTagsService";
import { usePostAPI } from "../../../services/Hooks";
import { paymentMethodService } from "../../../services/PaymentMethodService";
import {
    PaymentHeadService,
    PaymentService,
} from "../../../services/PaymentService";
import { S3Service } from "../../../services/S3Service";
import { Autocomplete } from "../../Autocomplete/Autocomplete";
import { awsSettings, baseAPI } from "../../constants";
import { CollectionRow } from "./CollectionRow";

const CreateCollection = () => {
    const [paymentToBePaid, setPaymentToBePaid] = useState<any>();
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 10,
    });
    const [descriptionTags, setDescriptionTags] = useState<any>();
    const [bank, setBank] = useState<any>();
    const [heads, setHeads] = useState<any>();
    const [columnsVisibility, setColumnsVisibility] = useState<any>({
        Id: true,
        Client: true,
        Balance: true,
        Name: true,
        Description: true,
        File: true,
        DescriptionTags: true,
    });
    const [open, setOpen] = useState(true);
    const [descriptionTagsPagination, setDescriptionTagsPagination] = useState({
        page: 0,
        limit: 10,
        count: 0,
    });

    const [headsPagination, setHeadsPagination] = useState({
        page: 0,
        limit: 10,
        count: 0,
    });

    const [bankPagination, setBankPagination] = useState({
        page: 0,
        limit: 10,
        count: 0,
    });

    const [count, setCount] = useState(0);

    const theme = useTheme();

    const [collectionResults, setCollectionResults] = useState<any>({});
    const [collections, setCollections] = useState<{
        [key: number]: any;
    }>({});
    const [showResponseDetails, setShowResponseDetails] = useState(false);
    const ref = useRef(0);

    const [headerRow, setHeaderRow] = useState({
        type: "",
        bank: "",
        status: "credit",
        descriptionTags: [],
        heads: [],
    });

    const isAllChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked = Object.values(collections)
            .slice(start, end)
            .every((x) => x.checked);

        return checked;
    }, [collections]);

    const isSomeChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked =
            !isAllChecked &&
            Object.values(collections)
                .slice(start, end)
                .some((x) => x.checked);

        console.log("isAllChecked", checked);

        return checked;
    }, [collections]);

    function handleAllCheck(
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setCollections((curr) => {
            const allChecked = Object.fromEntries(
                Object.entries(curr).map(([k, v], index) => {
                    const { page, limit } = pagination;

                    const start = page * limit;
                    const end = (page + 1) * limit;

                    console.log(start, end);

                    if (index >= start && index < end) {
                        return [
                            k,
                            {
                                ...v,
                                checked,
                            },
                        ];
                    }

                    return [k, v];
                })
            );

            return allChecked;
        });
    }

    const [activeResult, setActiveResult] = useState<any>(null);
    const [paymentMethods, setPaymentMethod] = useState<any>();
    const [feedback, setFeedback] = useState({
        message: "",
        hidden: false,
        severity: "null",
        loading: false,
    });
    const [clearFilters, setClearFilters] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [filter, setFilter] = useState({
        search: "",
        seachForShowing: "",
        clientType: "",
        client: "",
        bank: "",
        apply: 0,
        startDate: "",
        endDate: "",
        status: "",
        city: "",
    });

    const getCollectionToBePaid = async () => {
        const [data, err] = await PaymentService.getPaymentsToBePaid(
            pagination.page + 1,
            pagination.limit,
            {
                client: filter.client ? filter.client : null,
                search: filter.search ? filter.search : null,
                city: filter.city ? filter.city : null,
                ClientType: filter.clientType ? filter.clientType : null,
            }
        );

        if (data?.rows?.length) {
            setPaymentToBePaid(data?.rows);
            // const CollectionData = data.rows.reduce((prev: any, curr: any) => {
            //     console.log(curr);
            //     return {
            //         ...prev,
            //         [curr.id]: {
            //             status: "credit",
            //             client: curr.client.id,
            //             clientType: curr?.client.employee.clientType?.id,
            //             checked: false,
            //         },
            //     };
            // }, {});

            const CollectionData = data.rows.reduce((prev: any, curr: any) => {
                return {
                    ...collections,
                    ...prev,
                    [curr?.id]: {
                        client: curr.client.id,

                        clientType: curr?.client.employee.clientType?.id,
                        checked: collections[curr?.id]?.checked ?? true,
                        ...headerRow,
                    },
                };
            }, {});

            console.log(CollectionData);
            setCollections(CollectionData);
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
        } else {
            console.log("it hits");
            setPaymentToBePaid([]);
            setCollections([]);
        }
    };

    const getDescritpionTags = async () => {
        const [data, err] = await DescriptionTagsService.getDescriptionTags(
            1,
            1000
        );

        if (data.rows.length) {
            setDescriptionTags(data.rows);
        }
    };

    const getBank = async () => {
        const [data, err] = await paymentMethodService.getPaymentMethod(
            1,
            1000
        );

        if (data.rows.length) {
            setBank(data.rows);
        }
    };

    const getHeads = async () => {
        const [data, err] = await PaymentHeadService.getHeads(1, 1000);

        if (data.rows.length) {
            setHeads(data.rows);
        }
    };

    const [showColumns, setShowColumns] = useState(false);
    const [_updateSelected, setUpdateSelected] = useState<any>();
    const showColumnsBtn = useRef<HTMLButtonElement | null>(null);

    async function UploadFile(key: any, file: any) {
        await S3Service.uploadToS3(key, file);
    }

    const [CreateCollection, response, status, message] = usePostAPI(
        `${baseAPI}/payment/bulk`
    );

    if (response?.paymentIds?.length > 0) {
        for (const singlePayment of response.paymentIds) {
            const filterFile = Object.values(collections).find(
                (item) => item?.fileName == singlePayment?.fileName
            );

            if (filterFile.file) {
                const extension = filterFile.file.type.split("/")[1]; // e.g., 'jpg', 'png', 'pdf'
                const key = `${awsSettings.paymentDir}/${singlePayment.id}.${extension}`;
                // const key = `${awsSettings.paymentDir}/${singlePayment.id}`;
                if (filterFile) {
                    UploadFile(key, filterFile.file);
                }
                // const key = `${awsSettings.paymentDir}/${singlePayment.id}`;
                // if (filterFile) {
                //     UploadFile(key, filterFile.file);
                // }
            }
        }
    }

    async function handleSubmit(ev: any) {
        setFeedback({ ...feedback, loading: true });
        ev.preventDefault();

        const checkedPayments = Object.values(collections).filter(
            (payment) => payment.checked
        );

        if (!checkedPayments || checkedPayments.length == 0) {
            setFeedback({
                loading: false,
                message:
                    "You must checked a row in order to create a collection",
                hidden: false,
                severity: "error",
            });
        } else {
            CreateCollection({ payments: checkedPayments }, {});
        }
    }

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        ref.current++;
        const newRef = ref.current;
        const { value } = e.target;

        setFilter({ ...filter, seachForShowing: value });

        setTimeout(() => {
            if (newRef === ref.current) {
                setFilter((curr: any) => ({
                    ...curr,
                    ["search"]: value,
                }));

                ref.current = 0;
            }
        }, 1000);
    };

    const handleColumnVisibilityChange = (column: string) => {
        setColumnsVisibility((prev: any) => {
            const newVisibility = {
                ...prev,
                [column]: !prev[column],
            };

            localStorage.setItem(
                "createCollectionData",
                JSON.stringify(newVisibility)
            );

            return newVisibility;
        });
    };

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            search: "",
            seachForShowing: "",
            clientType: "",
            client: "",
            bank: "",
            apply: 0,
            startDate: "",
            endDate: "",
            status: "",
            city: "",
        });

        setCount(count + 1);
    };

    useEffect(() => {
        getCollectionToBePaid();
    }, [filter.apply, filter.search, count, pagination.page, pagination.limit]);

    useEffect(() => {
        const savedColumns = localStorage.getItem("createCollectionData");

        if (savedColumns) {
            setColumnsVisibility(JSON.parse(savedColumns));
        } else {
            setColumnsVisibility({
                Id: true,
                Client: true,
                Balance: true,
                Name: true,
                Description: true,
                File: true,
                DescriptionTags: true,
            });
        }
    }, []);

    useEffect(() => {
        getDescritpionTags();
        getBank();
        getHeads();
    }, []);

    return (
        <>
            <Grid container spacing={1} style={{ padding: "2rem" }}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/city/get-All"
                        setOutput={(ci) =>
                            setFilter({
                                ...filter,
                                city: ci?.id || "",
                            })
                        }
                        label="City"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        clear={clearFilters}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        labelKey="name"
                        label="Client Type"
                        api="/clientType/get-All"
                        setOutput={(r) =>
                            setFilter({
                                ...filter,
                                clientType: r?.id || null,
                            })
                        }
                        clear={clearFilters}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Autocomplete
                        label="Client"
                        api="/employee/all-employees"
                        labelKey="name"
                        setOutput={(opt) => {
                            if (!opt) return;

                            setFilter({
                                ...filter,
                                client: opt.map((item: any) => item.id),
                            });
                        }}
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            clientType: filter.clientType
                                ? filter.clientType
                                : null,
                            city: filter.city ? filter.city : null,
                        }}
                        clear={clearFilters}
                        multiple
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() =>
                            setFilter({ ...filter, apply: filter.apply + 1 })
                        }
                        size="small"
                        style={{ height: "80%" }}
                    >
                        Apply filter
                    </Button>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Tooltip title="Clear Filters">
                        <IconButton onClick={handleFilterClear} color="primary">
                            <ClearAllIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <span
                style={{
                    borderBottom: `1px solid  ${theme.palette.divider}`,
                    display: "block",
                }}
            ></span>

            {response && (
                <Grid item xs={12}>
                    <Alert
                        severity={status as any}
                        action={
                            <IconButton
                                onClick={() => {
                                    setShowResponseDetails(
                                        !showResponseDetails
                                    );
                                }}
                            >
                                <InfoOutlined fontSize="small" />
                            </IconButton>
                        }
                    >
                        <AlertTitle>
                            {`${response.successes.length} successes out of ${
                                response.successes.length +
                                response.failures.length
                            } `}
                        </AlertTitle>

                        <Collapse in={showResponseDetails}>
                            {response.successes?.map(
                                (
                                    suc: {
                                        message: string;
                                        id: number | string;
                                    },
                                    index: number
                                ) => (
                                    <Box key={index}>
                                        {`ID (${suc.id}) - ${suc.message}`}
                                    </Box>
                                )
                            )}

                            {response.failures.map(
                                (
                                    err: {
                                        message: string;
                                        id: number | string;
                                    },
                                    index: number
                                ) => (
                                    <Box key={index}>
                                        {`ID (${err.id}) - ${err.message}`}
                                    </Box>
                                )
                            )}
                        </Collapse>
                    </Alert>
                </Grid>
            )}

            <Grid
                container
                justifyContent="space-between"
                style={{ padding: "2rem" }}
            >
                <Grid
                    item
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                    md={4}
                >
                    {Object.keys(filter).includes("search") && (
                        <Grid item style={{ flex: "1" }}>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                variant="outlined"
                                value={filter.seachForShowing}
                                onChange={handleSearch}
                                label="Search"
                                size={"small"}
                            />
                        </Grid>
                    )}

                    <Tooltip title="Select Columns">
                        <IconButton
                            ref={showColumnsBtn}
                            onClick={() => setShowColumns(true)}
                        >
                            {showColumns ? (
                                <ViewColumn
                                    htmlColor={theme.palette.primary.main}
                                />
                            ) : (
                                <ViewColumnOutlined />
                            )}
                        </IconButton>
                    </Tooltip>

                    <Menu
                        open={showColumns}
                        anchorEl={showColumnsBtn.current}
                        onClose={() => setShowColumns(false)}
                        elevation={4}
                        variant="menu"
                    >
                        {Object.keys(columnsVisibility).map((col) => (
                            <MenuItem key={col} style={{ paddingLeft: 0 }}>
                                <Checkbox
                                    size="small"
                                    checked={columnsVisibility[col]} // Checked if the column is visible
                                    onChange={() =>
                                        handleColumnVisibilityChange(col)
                                    }
                                    color="primary"
                                />

                                <Typography>{col}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginTop: "10px" }}>
                    <TablePagination
                        component="div"
                        onPageChange={(ev, page) =>
                            setPagination({ ...pagination, page: page })
                        }
                        onRowsPerPageChange={(ev) =>
                            setPagination({
                                ...pagination,
                                limit: parseInt(ev.target.value),
                                page: 0,
                            })
                        }
                        count={pagination.count}
                        page={pagination.page}
                        rowsPerPage={pagination.limit}
                    />
                </Grid>
            </Grid>

            <section hidden={feedback.hidden} style={{ paddingBottom: "1rem" }}>
                <Alert severity={feedback.severity as any}>
                    {feedback.message}
                </Alert>
            </section>

            {paymentToBePaid && paymentToBePaid.length > 0 ? (
                <form onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow style={{ whiteSpace: "nowrap" }}>
                                        <TableCell>
                                            <FormControl>
                                                <Checkbox
                                                    checked={isAllChecked}
                                                    onChange={handleAllCheck}
                                                    indeterminate={
                                                        isSomeChecked
                                                    }
                                                />
                                            </FormControl>
                                        </TableCell>
                                        {columnsVisibility.Id && (
                                            <TableCell>ID</TableCell>
                                        )}
                                        {columnsVisibility.Client && (
                                            <TableCell>Client</TableCell>
                                        )}
                                        {columnsVisibility.Balance && (
                                            <TableCell>Balance</TableCell>
                                        )}
                                        {columnsVisibility.Name && (
                                            <TableCell>Name</TableCell>
                                        )}
                                        {columnsVisibility.Description && (
                                            <TableCell>Description</TableCell>
                                        )}
                                        {columnsVisibility.File && (
                                            <TableCell>File</TableCell>
                                        )}

                                        <TableCell>
                                            <Autocomplete
                                                setOutput={(bank: any) => {
                                                    setHeaderRow((curr) => ({
                                                        ...curr,
                                                        bank: bank?.id ?? "",
                                                    }));
                                                    setCollections(
                                                        Object.fromEntries(
                                                            Object.entries(
                                                                collections
                                                            ).map(([k, v]) => [
                                                                k,
                                                                {
                                                                    ...v,
                                                                    bank:
                                                                        bank?.id ??
                                                                        "",
                                                                    paymentName:
                                                                        bank?.name ??
                                                                        "",
                                                                },
                                                            ])
                                                        )
                                                    );
                                                }}
                                                labelKey="name"
                                                label="Bank"
                                                textFieldProps={{
                                                    variant: "outlined",
                                                    size: "small",
                                                }}
                                                paginationCall={(page) => {
                                                    setBankPagination(page);
                                                }}
                                                defaultOptions={bank}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                defaultOptions={[
                                                    {
                                                        label: "Payment",
                                                        status: "payment",
                                                        id: "payment",
                                                    },
                                                    {
                                                        label: "Adjustment",
                                                        status: "adjustment",
                                                        id: "adjustment",
                                                    },

                                                    {
                                                        label: "Advance",
                                                        status: "advance",
                                                        id: "advance",
                                                    },
                                                ]}
                                                setOutput={(des: any) => {
                                                    setHeaderRow((curr) => ({
                                                        ...curr,
                                                        type: des?.id ?? "",
                                                    }));
                                                    // if (!des) return;
                                                    setCollections(
                                                        Object.fromEntries(
                                                            Object.entries(
                                                                collections
                                                            ).map(([k, v]) => [
                                                                k,
                                                                {
                                                                    ...v,
                                                                    type:
                                                                        des?.id ??
                                                                        "",
                                                                },
                                                            ])
                                                        )
                                                    );
                                                }}
                                                labelKey="label"
                                                label="Status"
                                                textFieldProps={{
                                                    variant: "outlined",
                                                    size: "small",
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                setOutput={(head: any) => {
                                                    setHeaderRow((curr) => ({
                                                        ...curr,
                                                        heads: head ?? "",
                                                    }));
                                                    setCollections(
                                                        Object.fromEntries(
                                                            Object.entries(
                                                                collections
                                                            ).map(([k, v]) => [
                                                                k,
                                                                {
                                                                    ...v,
                                                                    ["heads"]:
                                                                        head,
                                                                },
                                                            ])
                                                        )
                                                    );
                                                }}
                                                labelKey="name"
                                                label="Heads"
                                                textFieldProps={{
                                                    variant: "outlined",
                                                    size: "small",
                                                }}
                                                multiple
                                                paginationCall={(page) => {
                                                    setHeadsPagination(page);
                                                }}
                                                defaultOptions={heads}
                                            />
                                        </TableCell>

                                        {columnsVisibility.DescriptionTags && (
                                            <TableCell>
                                                <Autocomplete
                                                    setOutput={(
                                                        descTags: any
                                                    ) => {
                                                        setHeaderRow(
                                                            (curr) => ({
                                                                ...curr,
                                                                descriptionTags:
                                                                    descTags.map(
                                                                        (
                                                                            item: any
                                                                        ) =>
                                                                            item
                                                                    ),
                                                            })
                                                        );
                                                        setCollections(
                                                            Object.fromEntries(
                                                                Object.entries(
                                                                    collections
                                                                ).map(
                                                                    ([
                                                                        k,
                                                                        v,
                                                                    ]) => [
                                                                        k,
                                                                        {
                                                                            ...v,
                                                                            ["descriptionTags"]:
                                                                                descTags.map(
                                                                                    (
                                                                                        item: any
                                                                                    ) =>
                                                                                        item
                                                                                ),
                                                                        },
                                                                    ]
                                                                )
                                                            )
                                                        );
                                                    }}
                                                    labelKey="name"
                                                    label="DescriptionTags"
                                                    textFieldProps={{
                                                        variant: "outlined",
                                                        size: "small",
                                                        // required: true,
                                                    }}
                                                    multiple
                                                    paginationCall={(page) => {
                                                        setDescriptionTagsPagination(
                                                            page
                                                        );
                                                    }}
                                                    defaultOptions={
                                                        descriptionTags
                                                    }
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {paymentToBePaid?.map(
                                        (coll: any, id: number) => (
                                            <CollectionRow
                                                count={
                                                    id +
                                                    1 +
                                                    pagination.limit *
                                                        pagination.page
                                                }
                                                key={id}
                                                collectionResults={
                                                    collectionResults
                                                }
                                                collectee={coll}
                                                collections={collections}
                                                setCollections={setCollections}
                                                setActiveResult={
                                                    setActiveResult
                                                }
                                                checkAll={isAllChecked}
                                                columnsVisibility={
                                                    columnsVisibility
                                                }
                                                descTags={descriptionTags}
                                                bank={bank}
                                                headsData={heads}
                                                descTagspagination={
                                                    descriptionTagsPagination
                                                }
                                                headsPagination={
                                                    headsPagination
                                                }
                                                bankPagination={bankPagination}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Button
                        color="primary"
                        fullWidth
                        variant="outlined"
                        disabled={status === "loading"}
                        size="large"
                        type="submit"
                        endIcon={
                            status === "loading" ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                    >
                        {status === "loading"
                            ? "Creating collections..."
                            : "Create Collections"}
                    </Button>
                </form>
            ) : (
                <Grid item xs={12}>
                    <Alert severity="info">
                        No pending payments to create a collection against
                    </Alert>
                </Grid>
            )}
        </>
    );
};

export default CreateCollection;
